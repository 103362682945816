import user1 from 'media/user1.png';
import essi3 from 'media/essi3.jpg';
import energie3 from 'media/energie3.jpg';
import user2 from 'media/user2.png';
import user4 from 'media/chint.jpg';
import musa from 'media/musaDirector.jpg';
import prime from 'media/Image/clients/prime.jpg';
import silverberry from 'media/Image/clients/silverberry.png'

export const comments = [
  {
    'img': user1,
    'direction': 'user1Direction',
    'name': 'user1Name',
    'text': 'user1Text'
  },
  {
    'img': user4,
    'name': 'user2Name',
    'direction': 'user2Direction',
    'text': 'user2Text'
  },
  // {
  // 	img: chint3,
  // 	name: 'Даврон',
  // 	direction: 'Директор: Chint',
  // 	text: 'После внедрения Ритм наши продажи выросли за счет того, что мы можем принимать правильные решения, глядя на аналитику на нашем телефоне и компьютере. И мы сократили ввоз ненужных грузов. В дополнение к нашей дистрибуции, Ритм также автоматизировал наши магазины.'
  // },
  {
    'img': user2,
    'name': 'user3Name',
    'direction': 'user3Direction',
    'text': 'user3Text'
  },
  {
    'img': essi3,
    'name': 'user4Name',
    'direction': 'user4Direction',
    'text': 'user4Text'
  },
  {
    'img': energie3,
    'name': 'user5Name',
    'direction': 'user5Direction',
    'text': 'user5Text'
  },
  {
    'img': musa,
    'name': 'user6Name',
    'direction': 'user6Direction',
    'text': 'user6Text'
  },
  // {
  //   'img': marakandaSweets,
  //   'name': 'user7Name',
  //   'direction': 'user7Direction',
  //   'text': 'user7Text'
  // },
  {
    'img': prime,
    'name': 'user8Name',
    'direction': 'user8Direction',
    'text': 'user8Text'
  },
  {
    'img': silverberry,
    'name': 'user9Name',
    'direction': 'user9Direction',
    'text': 'user9Text'
  }
];
