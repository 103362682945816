export default {
  ru: {
    column1Title1: 'Дистрибьюторам',
    column1Title2: 'Магазинам',
    column1Title3: 'Телеграмм бот',
    column1Title4: 'Автомобильные весы',
    column1Title5: 'Интернет магазин',

    column2Title1: 'Компания',
    column2Title2: 'Политика конфиденциальности',
    column2Title3: 'Правила использования',
    column2Title4: 'Партнеры',

    column3Title1: 'Ресурсы',
    column3Title2: 'Служба поддержки',
    column3Title3: 'Отзывы',

    copyRightTitle: 'OOO «Wiener Deming». Все права защищены.',
    public_offer: 'Публичная оферта',

    "address": "Узбекистан, Ташкент, Яшнабадский район Истикбол, 25",
    "reference_point": "Ориентир: Посольство Французской Республики",
    "copyright_text": "Ритм. Все права защищены.",
  },

  uz: {
    column1Title1: 'Distribyutorlar',
    column1Title2: 'Do\'konlar',
    column1Title3: 'Telegram bot',
    column1Title4: 'Avtomobil og\'irliklari',
    column1Title5: 'Internet-do\'kon',

    column2Title1: 'Kompaniya',
    column2Title2: 'Maxfiylik siyosati',
    column2Title3: 'Foydalanish shartlari',
    column2Title4: 'Hamkorlar',

    column3Title1: 'Resurslar',
    column3Title2: 'Qo\'llab-quvvatlash xizmati',
    column3Title3: 'Sharhlar',

    copyRightTitle: 'OOO «Wiener Deming». Barcha huquqlar himoyalangan.',
    public_offer: 'Ommaviy taklif',

    "address": "O‘zbekiston, Toshkent, Yashnabad tumani, Istiqbol ko‘chasi, 25.",
    "reference_point": "Mo'ljal: Fransiya Respublikasining elchixonasi",
    "copyright_text": "Ritm. Barcha huquqlar himoyalangan."
  },

  en: {
    column1Title1: 'Distributors',
    column1Title2: 'Markets',
    column1Title3: 'Telegram bot',
    column1Title4: 'Car weights',
    column1Title5: 'Internet shop',

    column2Title1: 'Company',
    column2Title2: 'Privacy Policy',
    column2Title3: 'Terms of Use',
    column2Title4: 'Partners',

    column3Title1: 'Resources',
    column3Title2: 'Support',
    column3Title3: 'Reviews',

    copyRightTitle: 'OOO «Wiener Deming». All rights reserved.',
    public_offer: 'Public offer',

    "address": "Uzbekistan, Tashkent, Yashnabad District, Istikbol Street, 25.",
    "reference_point": "Landmark: Embassy of the French Republic",
    "copyright_text": "Ритм. All rights reserved."
  },
}
