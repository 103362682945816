import React from 'react';
import {Box} from '@material-ui/core';
import Header from './CommentHeader';
import CommentBody from './CommentBody';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled('div')`
	.desktop {
		.swiper-slide {
			margin-left: 20px !important;
		}
	}

	@media only screen and (max-width: 992px) {
		.desktop {
			display: none;
    }

    .mobile {
      display: block;
    }
  }
  @media only screen and (min-width: 992px) {
    .mobile {
      display: none;
	}

	  .desktop {
		  display: block;

		  .swiper-slide {
			  min-width: 374px;
		  }
	  }
  }

	${({styles}) => styles}

`;

const MobileWrapper = styled('div')`
	width: 330px;
	height: 377px;
	padding: 24px;
	overflow: hidden;
	border-radius: 40px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

const Comment = (props) => {
	const {
		text,
		img,
		name,
		direction,
		styles
	} = props
	return (
		<Wrapper styles={styles}>
			<Box
				padding={'30px'}
				boxShadow={'0px 0px 64px rgba(0, 0, 0, 0.08)'}
				className={'desktop'}
				maxWidth={'430px'}
				minWidth={'430px'}
				overflow={'hidden'}
				borderRadius={'40px'}
			>
				<Header img={img} name={name} direction={direction}/>
				<CommentBody text={text}/>
			</Box>
			<MobileWrapper className={'mobile'}>
				<Header img={img} name={name} direction={direction}/>
				<CommentBody text={text} isMobile={true}/>
			</MobileWrapper>
		</Wrapper>
	);
};

Comment.propTypes = {
	'text': PropTypes.string,
	'img': PropTypes.string,
	'name': PropTypes.string,
	'styles': PropTypes.object,
	'direction': PropTypes.string
};

export default Comment;
