import {BrowserRouter as Router, Switch} from 'react-router-dom';
import routes from './constants/router';
import _ from 'lodash';
import React from 'react';
import Loader from './components/Loader';
import MultiRouter from './tools/redirect';
import ReactGA from 'react-ga';
import i18n from 'i18next'
import locales from 'constants/locales'
import {initReactI18next} from 'react-i18next'

i18n
    .use(initReactI18next)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: { ...locales },
    lng: 'ru', // if you're using a language detector, do not define the lng option
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

class App extends React.Component {
  state = {
    'loading': false
  };

  setGA = () => {
    ReactGA.initialize('UA-222080071-1');
    ReactGA.pageview('https://ritm.uz' + window.location.search);
  };

  componentDidMount () {
    this.setGA();
  }

  render () {
    const { loading } = this.state;
    if (!loading) {
      console.error = () => '';
      return (
			 <Router>
				 <div>
					 <Switch>
						 {
							 _.map(routes, (item, key) => {
								 return (
										<MultiRouter key={key} {...item} />
								 );
							 })
						 }
					 </Switch>
				 </div>
			 </Router>
      );
    }
    return (
		 <Loader/>
    );
  }
}

export default App;
