import Home from 'components/Pages/Home';
import * as ROUTES from './routes';
import {PRIVACY_POLICY} from "./routes";
import PrivacyPolicy from "../../components/Pages/PrivacyPolicy";

const routes = [
  {
    'path': ROUTES.PRIVACY_POLICY,
    'component': PrivacyPolicy
  },
  {
    'path': ROUTES.ROOT_PATH,
    'component': Home
  }
];

export default routes;
