import React from 'react';
import {Route} from 'react-router-dom';

const MultiRouter = (route) => {
	return (
	 <React.Fragment>
		 <Route
			exact
			path={route.path}
			render={props => {
			  return (
				 <route.component {...props} />
			  );
			}}
		 />
		 {route.routes.map((route, index) => {
			 return (
				<MultiRouter
				 key={index}
				 {...route}
				/>
			 );
		 })}
	 </React.Fragment>
  );
};

MultiRouter.defaultProps = {
  'routes': []
};

export default MultiRouter;
