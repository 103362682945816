// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ru: {
    Distributors: 'Дистрибьюторам',
    Markets: 'Магазинам',
    TelegramBot: 'Телеграмм бот',
    CarWeights: 'Автомобильные весы',
    InternetShop: 'Интернет магазин',
    GoToSite: 'Перейти на сайт ',
    Search: 'Поиск',
    AdvicesFromRitm: 'Ответы на вопросы и советы от команды Ритм'
  },

  uz: {
    Distributors: 'Distribyutorlar',
    Markets: 'Do\'konlar',
    TelegramBot: 'Telegram bot',
    CarWeights: 'Avtomobil og\'irliklari',
    InternetShop: 'Internet-do\'kon',
    GoToSite: 'Veb-saytga o\'tish',
    Search: 'Qidiruv',
    AdvicesFromRitm: 'Ritm jamoasining savollarga javoblari va maslahatlari'
  },

  en: {
    Distributors: 'Distributors',
    Markets: 'Markets',
    TelegramBot: 'Telegram bot',
    CarWeights: 'Car weights',
    InternetShop: 'Internet shop',
    GoToSite: 'Go to site',
    Search: 'Search',
    AdvicesFromRitm: 'Answers to questions and advice from the Rythm team'
  },
}
