import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import _ from 'lodash';
import phone from 'media/Image/single_phone.svg';
import MainContainer from '../../../MainContainer';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

const Wrapper = styled(Row)`
  margin-right: 0;
  @media only screen and (max-width: 1170px) {
    padding: 80px 20px !important;
  }
  margin-left: 0;
  width: 100%;
  background: #F6F7FA;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media only screen and (max-width: 992px) {
    padding: 50px 20px !important;
    .center {
      display: flex;
      align-items: center;
      justify-content: flex-start !important;
    }
  }

  padding: 80px 0;
  @media only screen and (max-width: 995px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }
  }
  @media only screen and (min-width: 995px) {
    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
  }
`;

const Title = styled('p')`
  font-family: var(--bold);
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 150%;
  text-align: center;
  color: #18214D;
  margin-bottom: 60px;

  @media only screen and (max-width: 992px) {
    margin-bottom: 25px;
    font-size: 35px !important;
    text-align: left;
  }
  @media only screen and (max-width: 470px) {
    margin-bottom: 5px;
    font-size: 24px;
    text-align: left;
  }

`;

const Steps = styled('div')`
  width: 100%;

  & > :not(:last-child) {
    margin-bottom: 48px;
  }
`;

const Img = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 235px;
  }
  @media only screen and (max-width: 992px) {
		padding: 40px 0 40px 0;
  }
`;

const Step = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  padding: 24px;
  border-radius: 16px;
  position: relative;

  &:before {
    content: '';
    left: 0;
		top: 0;
    position: absolute;
    width: 3px;
    height: 76px;
    background: #2684FF;
    border-radius: 16px;
    transform: translateY(50%)
  }
`;

const ItemTitle = styled('p')`
  font-family: var(--bold);
  letter-spacing: -0.01em;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #18214D;
  margin-bottom: 0;
  @media only screen and (max-width: 420px) {
  	font-size: 17px;
	}
`;

const StepText = styled('p')`
  font-style: normal;
  font-weight: normal;
  margin-top: 12px !important;
  font-size: 16px;
  line-height: 180%;
  color: #5C6077;
	max-width: 362px;
  margin-bottom: 0;
	@media only screen and (max-width: 420px) {
		font-size: 14px;
	}
`;

const step = (object, t) => {
  return (
	 <Steps>
		 {
			 _.map(object, (item, key) => {
				 const title = _.get(item, ['title']);
				 const text = _.get(item, ['text']);
				 return (
					<Step key={key}>
						<ItemTitle>
							{t(title)}
						</ItemTitle>
						<StepText>
							{t(text)}
						</StepText>
					</Step>
				 );
			 })
		 }
	 </Steps>
  );
};

const Sales = ({ object, image }) => {
	const {t} = useTranslation()
  return (
	 <Wrapper>
		 <MainContainer>
			 <Col xs={12} md={12} className={'center'}>
				 <Col sm={12} md={8} lg={8}>
					 <Title>
						 {t(_.get(object, ['title']))}
					 </Title>
				 </Col>
			 </Col>
			 <Col xs={12}>
				  <Row>
            <Col lg={4}>
              {step(_.get(object, ['first']), t)}
            </Col>
            <Col lg={4}>
              <Img>
                <img src={image || phone} alt=""/>
              </Img>
            </Col>
            <Col lg={4}>
              {step(_.get(object, ['second']), t)}
            </Col>
				  </Row>
			 </Col>
		 </MainContainer>
	 </Wrapper>
  );
};

Sales.propTypes = {
  'object': PropTypes.object,
  'image': PropTypes.string
};

export default Sales;
