import React from 'react';
import styled from 'styled-components';
import {Col, Row} from 'react-bootstrap';
import getClients from 'constants/home/client';
import MainContainer from 'components/MainContainer';
import _ from 'lodash';
import Marquee from "react-fast-marquee";
import SlideUp from "../../../SlideUp/SlideUp";

const Wrapper = styled('div')`
  .swiper-wrapper {
    align-items: center !important;
    max-height: 80px;

  }
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 50px 0;

  @media only screen and (max-width: 992px) {
    margin-bottom: -20px;
  }
  @media only screen and (max-width: 360px) {
    .slick-slide {
      display: flex;
      align-items: center;
      justify-content: center
    }
  }

  .slick-initialized .slick-slide {
    display: flex;
    height: 80px;
    align-items: center;
  }


  @media only screen and (max-width: 360px) {
    .slick-slider {
      margin-bottom: -80px !important;
    }
  }


  & .row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 50px;

    & > :last-child > div {
      &:before {
        content: '';
        display: none;
      }
    }
  }
`;

const Title = styled('p')`
  font-family: var(--bold);
  font-style: normal;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 42px;
  line-height: 51px;
  text-align: center;
  @media only screen and (max-width: 992px) {
    font-size: 35px !important;
  }
  @media only screen and (max-width: 360px) {
    margin-bottom: -80px !important;
  }
  color: #18214D;
`;

const Img = styled('div')`
  display: flex;
  width: fit-content !important;
  align-items: center;
  margin-right: 30px;
  border: none !important;
  outline: none !important;
    
    img {
        width: 158px !important;
    }
`;


const Centered = styled(Row)`
  margin-top: 0 !important;
`

const mappedItems = (items) => {
    return _.map(items, (item, key) => {
        return (<Img key={key}>
            <img width={'158px'} src={item} alt=""/>
        </Img>);
    });
}

const Clients = ({t, isHeader}) => {
    const [part1, part2, part3] = getClients();

    return (<Wrapper>
        <MainContainer style={{maxWidth: '100%'}}>
            <Row>
                <Col xs={12}>
                    <SlideUp>

                        <Title style={{marginBottom: '30px'}}>
                            {t('ourClients')}
                        </Title>
                    </SlideUp>
                </Col>
                <Col xs={12}>
                    <Centered>
                        <Marquee scrolldelay={100} behavior="scroll" direction="left" scrollamount="10">
                            <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                                {mappedItems(part1)}
                            </div>
                        </Marquee>
                    </Centered>
                    <Centered>
                        <Marquee behavior="scroll" direction="right" scrollamount="10">
                            <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                                {mappedItems(part3)}
                            </div>
                        </Marquee>
                    </Centered>
                    <Centered>
                        <Marquee scrolldelay={100} behavior="scroll" direction="left" scrollamount="10">
                            <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                                {mappedItems(part2)}
                            </div>
                        </Marquee>
                    </Centered>
                </Col>
            </Row>
        </MainContainer>
    </Wrapper>);
};

export default Clients;
