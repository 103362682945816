import React, {useState} from "react";
import styled from "styled-components";
import bg from "../../../media/Image/bg.svg";
import MainContainer from "../../MainContainer";
import Navbar from "../../Navbar";
import {useTranslation} from "react-i18next";
import Footer from "../../Footer/Footer";
import {Col, Row} from "react-bootstrap";
import _ from 'lodash'
import Check from "../../../media/Icon/Check";
import Plus from "../../../media/Icon/Plus";
import Minus from "../../../media/Icon/Minus";
import PricingOne from "../../../media/Icon/PricingOne";
import PricingTwo from "../../../media/Icon/PricingTwo";
import PricingThree from "../../../media/Icon/PricingThree";

export const priceItems = [
    {
        icon: <PricingOne/>,
        price: '120 000',
        discountPrice: '96 000',
        currency: 'sum',
        period: 'month_for_license',
        positions: [
            {
                name: 'sales_representative'
            },
            {
                name: 'salesperson'
            },
            {
                name: 'one_selling'
            }
        ]
    },
    {
        icon: <PricingTwo/>,
        price: '84 000',
        discountPrice: '67 200',
        currency: 'sum',
        period: 'month_for_license',
        positions: [
            {
                name: 'supervisor'
            },
            {
                name: 'admin'
            },
            {
                name: 'deliveryman'
            },
            {
                name: 'merchandiser'
            }
        ]
    },
    {
        icon: <PricingThree/>,
        price: 'free',
        discountPrice: 'free',
        currency: '',
        period: 'only_for_one_user',
        positions: [
            {
                name: 'admin'
            },
            {
                name: 'operator'
            },
            {
                name: 'cashier'
            },
            {
                name: 'warehouse_manager'
            }
        ]
    }
]

export const questionItems = [
    {
        title: 'Какова стоимость программы?',
        content: 'Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного' +
            ' текста рыбы на русском языке, а начинающему оратору отточить навык публичных' +
            ' выступлений в домашних условиях. При создании генератора мы использовали небезизвестный универсальный код речей' +
            '. Текст генерируется абзацами случайным образом от двух до десяти предложений в абзаце, что позволяет сделать' +
            ' текст более привлекательным и живым для визуально-слухового восприятия.'
    },
    {
        title: 'Какова стоимость программы?',
        content: 'Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного' +
            ' текста рыбы на русском языке, а начинающему оратору отточить навык публичных' +
            ' выступлений в домашних условиях. При создании генератора мы использовали небезизвестный универсальный код речей' +
            '. Текст генерируется абзацами случайным образом от двух до десяти предложений в абзаце, что позволяет сделать' +
            ' текст более привлекательным и живым для визуально-слухового восприятия.'
    }
]

export const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${bg}) center center / cover;
  padding-bottom: 150px;

  .swiper-slide {
    margin-left: -10px !important;
  }

  @media only screen and (max-width: 1000px) {
    padding-bottom: 80px;
  }
`;

export const Wrapper2 = styled('div')`
  width: 100%;
  display: flex;

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  align-items: center;
  justify-content: center;
  padding: 80px 0 80px 0;

  @media only screen and (max-width: 992px) {
    padding: 50px 0 20px 0;
  }
  @media only screen and (max-width: 992px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }
  }
  @media only screen and (min-width: 992px) {
    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }
  }

  & .row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    & > :last-child > div {
      &:before {
        content: '';
        display: none;
      }

      @media only screen and (max-width: 992px) {
        &:before {
          content: '';
          display: block;
        }
      }
    }
  }
`;

export const Header = styled(Row)`
  position: relative;
  margin: auto;
  padding-top: 167px;
  height: 100%;
  z-index: 1000;

  .desktop {
    & > .row {
      margin-left: 0 !important;
    }
  }

  .mobile {
    display: none;
    margin-top: 55px;
  }


  @media only screen and (max-width: 1170px) {
    .desktop {
      display: none !important;
    }

    .mobile {
      display: block;
    }
  }
  @media only screen and (min-width: 1570px) {
    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }
  }
  @media only screen and (max-width: 500px) {
    & .MuiButtonBase-root {
      margin-right: 0 !important;
    }
  }
  @media only screen and (max-width: 574px) {
    .slick-track {
      width: 100000000000px !important;
    }

    .slick-slide {
      width: 100%;
    }
  }
  @media only screen and (max-width: 315px) {
    .slick-slide {
      width: 240px !important;
    }
  }
  @media only screen and (max-width: 359px) {
    .slick-track {
      width: 9000px !important;
    }
  }
  @media only screen and (max-width: 580px) {
    & > .row {
      & > :first-child {
        .row {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: flex-start;
        }
      }
    }
  }

  & .swiper-slide {
    min-width: 230px !important;
    @media only screen and (max-width: 576px) {
      margin-left: 45px;
      & > div {
        padding-top: 16px;
        padding-left: 16px;
      }
    }
  }

  & .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  & > .row {
    display: flex;
    justify-content: center;
    align-items: center;

    .swiper {
      margin-top: 55px;
    }

    & > :first-child {
      .row {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
      }
    }
  }
`;

export const Title = styled('p')`
  font-family: var(--bold);
  text-align: center;
  font-style: normal;
  
  font-weight: bold;
  font-size: 41px;
  line-height: 150%;
  text-align: center;
  color: #18214D;
  @media only screen and (max-width: 580px) {
    font-size: 120px;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
    margin: auto;
  }
  @media only screen and (max-width: 500px) {
    font-size: 24px;
    width: 100%;
    margin-bottom: 8px !important;
  }
  @media only screen and (max-width: 350px) {
    font-size: 24px;
    width: 100%;
  }
`;

export const Text = styled.p`
  width: 100%;
  display: flex;

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
  @media only screen and (max-width: 500px) {
    font-size: 16px;
  }
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 180%;
  text-align: center;
  color: #18214D;
  margin-top: 12px;
`;

export const BoxesWrapper = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  -webkit-box-pack: justify;
  justify-content: space-between;
  grid-gap: 25px;
  justify-items: center;
  padding: 0 20px;

  @media only screen and (max-width: 350px) {
    
  }
`

export const PriceBox = styled('div')`
  width: 100%;
  height: max-content;
  background: transparent;
  box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3px;

  .top {
    width: 100%;
    height: 110px;
    background: #fff;
    padding: 20px 30px;
    font-weight: 700 !important;

    .title {
      font-family: var(--bold);
      color: #18214D;
      font-size: 33px !important;
    }

    .subTitle {
      color: #71717a;
      font-size: 18px;
    }
  }

  .middle {
    padding: 20px 30px;
    width: 100%;
    height: 220px;
    background: #fff;
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 10px;

    .position {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 600;
      font-size: 18px;
      color: #71717a;

      img, svg {
        width: 20px;
      }
    }
  }
`

export const TabsBox = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding:0  20px;
  position: relative;

  @media only screen and (max-width: 425px) {
    padding: 0 20px;
    & > :first-child {
      width: 100%;
    }
  }
  @media only screen and (max-width: 992px) {
    & > :last-child {
      display: none;
    }
  }
`

export const Tabs = styled('div')`
  width: max-content;
  margin: 0 20px;
  background-color: #2684FF2A;
  border-radius: 80px;
  height: max-content;
  display: flex;
  padding: 10px;
  
`

export const Tab = styled('div')`
  background: ${({ active }) => active ? '#2684FF' : 'transparent'};
  color: ${({ active }) => active ? '#FFFFFF' : '#18214D'};
  font-size: 20px;
  transition: all .2s ease;
  font-weight: 600;
  font-family: var(--bold);
  border-radius: 80px;
  padding: 10px;
  width: 200px;
  text-align: center;
  cursor: pointer;
`

export const QuestionsBox = styled('div')`
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`

export const QuestionBox = styled('div')`
  width: 100%;
  cursor: pointer;
  
  .top {
    display: flex;
    height: 85px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #DDDFE5;
    border-radius: 10px;
    padding: 20px 35px;
    background: #fff;
  }
  
  .top.after {
    border: none !important;
    background: #F6F7FA !important;
  }
  
  .title {
    color: #18214D;
    font-weight: 600;
    font-size: 22px;
    line-height: 40px;
  }
  
  .content {
    width: 100%;
    height: 0;
    overflow: hidden;
    font-size: 18px;
    font-weight: 400;
    color: #5C6077;
    background: #F6F7FA;
    border-radius: 10px;
  }

  .content.show {
    margin-top: 5px;
    height: max-content;
    padding: 20px 35px;
  }
`

const Prices = (props) => {

    const {t} = useTranslation()
    const [tab, setTab] = useState(true)
    const [selected, setSelected] = useState(null)

    const toggle = (index) => {
        if (selected === index) {
            return setSelected(null)
        }

        setSelected(index)
    }

    const tabs = (
        <TabsBox>
            <Tabs>
                <Tab active={tab} onClick={() => setTab(true)}>Ежемесячно</Tab>
                <Tab active={!tab} onClick={() => setTab(false)}>Ежегодно</Tab>
            </Tabs>
        </TabsBox>
    )

    const priceBoxes = (
        <div>
            {tabs}
            <BoxesWrapper>
                {_.map(priceItems, (priceItem, index) => {
                    const price = _.get(priceItem, 'price')
                    const discountPrice = _.get(priceItem, 'discountPrice')
                    const currency = _.get(priceItem, 'currency')
                    const period = _.get(priceItem, 'period')
                    const positions = _.get(priceItem, 'positions')
                    return (
                        <PriceBox key={index}>
                            <div className={'top'}>
                                <div className={'title'}>
                                    {tab ? price : discountPrice} {currency}
                                </div>
                                <div className={'subTitle'}>
                                    {period}
                                </div>
                            </div>
                            <div className={'middle'}>
                                {_.map(positions, (position) => {
                                    return (
                                        <div className={'position'}>
                                            <Check />
                                            {position.name}
                                        </div>
                                    )
                                })}
                            </div>
                        </PriceBox>
                    )
                })}
            </BoxesWrapper>
        </div>
    )

    const header = () => (
        <Wrapper>
            <MainContainer style={{position: 'relative'}}>
                <Navbar />
                <Header>
                    <Row>
                        <Col xs={12} lg={12} md={12} sm={12}>
                            <Title>
                                {t('prices')}
                            </Title>
                        </Col>
                    </Row>
                </Header>
                {priceBoxes}
            </MainContainer>
        </Wrapper>
    )

    const questions = (
        <Wrapper2>
            <MainContainer>
                <Row>
                    <Col xs={12}>
                        <Title>
                            Часто задаваемые вопросы
                        </Title>
                    </Col>
                </Row>
                <QuestionsBox>
                    {_.map(questionItems, (item, index) => {
                        return (
                            <QuestionBox>
                                <div className={selected === index ? 'top after' : 'top'} onClick={() => toggle(index)}>
                                    <div className={'title'}>
                                        {item.title}
                                    </div>
                                    {selected === index ? <Minus /> : <Plus />}
                                </div>
                                <div className={selected === index ? 'content show' : 'content'}>
                                    {item.content}
                                </div>
                            </QuestionBox>
                        )
                    })}
                </QuestionsBox>
            </MainContainer>
        </Wrapper2>
    )

    return (
        <>
            {header(t)}
            {questions}
            <Footer />
        </>
    )
}

export default Prices
