export const ROOT_PATH = '/';
export const PRIVACY_POLICY = '/privacy-policy/';

export const PRODUCTS = '/rhythm-pos/';
export const RHYTHM_AGENT = '/rhythm-agent/';
export const RHYTHM_ECOMMERCE = '/rhythm-ecommerce/';
export const RHYTHM_DELIVERY = '/rhythm-delivery/';
export const RHYTHM_POS = '/rhythm-pos/';
export const RHYTHM_WEIGHT = '/rhythm-weight/';

export const COMPANY = '/company/';
export const PRICES = '/prices/';

export const FAQ = '/faq/'

export const FAQ_DETAIL = '/faq/:id'
