import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col, Row} from 'react-bootstrap';
import bg from '../../../media/Image/bg.svg';
import Clients from './components/Clients';
import Comments from '../../Comments/Comments';
import Contact from '../../Form/Contact';
import Navbar from '../../Navbar';
import Footer from '../../Footer/Footer';
import MainContainer from '../../MainContainer';
import {useTranslation} from "react-i18next";
import _ from "lodash";
import Check from "../../../media/Icon/Check";
import {BoxesWrapper, PriceBox, priceItems, Tab, Tabs, TabsBox} from "../Prices/Prices";
import Section from "../Agent/components/Section";
import {firstProduct} from "../../../constants/products/sections";
import Button from "../../Button";
import img6 from 'media/Image/new/classification.png'
import img7 from 'media/Image/pages/7.jpg'
import mob1 from 'media/Image/pages/m2.png'
import mob3 from 'media/Image/pages/m3.png'
import debt from 'media/Image/new/debt.png'
import stock from 'media/Image/features/stock.png'
import finance from 'media/Image/new/pandl.png'
import profit from 'media/Image/new/profit.png'
import balance from 'media/Image/features/balance.png'
import dashboard from 'media/Image/new/dashboard.png'
import orders from 'media/Image/features/orders.png'
import kom from 'media/Image/features/kom.jpg'
import reports from 'media/Image/new/reports.png'
import stat from 'media/Image/new/stat.png'
import border from 'media/Image/new/border.jpg'
import Marquee from "react-fast-marquee";
import SlideUp from "../../SlideUp/SlideUp";
import Services from "./components/Services";
import {productsSection} from "../../../constants/services";
import FlexibleReport from "../Agent/components/FlexibleReport";
import Projects from "../Agent/components/Projects";
import Features from "../../Features/Features";
import Collaction from "../../Collaction";
import Arrow from '../../../media/Image/arrow.svg'
import Slider from "react-slick";
import { Lightbox } from "react-modal-image";

const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${bg}) center center / cover;
  padding-bottom: 80px;
  @media only screen and (max-width: 600px) {
    margin-bottom: 70px !important;
  }

    @media only screen and (max-width: 705px) {
        .desktop {
            display: none;
        }

        .mobile {
            display: block;
        }
    }
    @media only screen and (min-width: 705px) {
        .mobile {
            display: none;
        }

        .desktop {
            display: block;
        }
    }
  margin-bottom: 50px;
    
    .slick-dots {
        bottom: -50px;
    }

    .slick-dots li {
        & button:before {
            width: 8px;
            content: '';
            background-color: #5C6077;
            border-radius: 50%;
            height: 8px;
        }

        &.slick-active button:before {
            background-color: #2684FF;
        }
    }

    .swiper-slide {
        margin-right: 35px !important;
    }
`;


const Sections = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -49px;
`;


const Header = styled(Row)`
  position: relative;
  margin: auto;
  height: 100%;
  z-index: 1000;
  margin-top: 160px;

    @media only screen and (max-width: 1170px) {
        margin-top: 100px;
    }
    
  @media only screen and (max-width: 1170px) {  
    padding: 20px;
  }

  .desktop {
    & > .row {
      margin-left: 0 !important;
    }
  }

  .mobile {
    display: none;
    margin-top: 55px;
  }


  @media only screen and (max-width: 1170px) {
    .desktop {
      display: none !important;
    }

    .mobile {
      display: block;
    }
  }
  @media only screen and (min-width: 1570px) {
    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }
  }
  @media only screen and (max-width: 500px) {
    & .MuiButtonBase-root {
      margin-right: 0 !important;
    }
  }
  @media only screen and (max-width: 574px) {
    .slick-track {
      width: 100000000000px !important;
    }

    .slick-slide {
      width: 100%;
    }
  }
  @media only screen and (max-width: 315px) {
    .slick-slide {
      width: 240px !important;
    }
  }
  @media only screen and (max-width: 359px) {
    .slick-track {
      width: 9000px !important;
    }
  }
  @media only screen and (max-width: 580px) {
    & > .row {
      & > :first-child {
        .row {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: flex-start;
        }
      }
    }
  }

  & .swiper-slide {
    min-width: 230px !important;
    @media only screen and (max-width: 576px) {
      margin-left: 45px;
      & > div {
        padding-top: 16px;
        padding-left: 16px;
      }
    }
  }

  & .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  & > .row {
    display: flex;
    justify-content: center;
    align-items: center;

    .swiper {
      margin-top: 55px;
    }

    & > :first-child {
      .row {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
      }
    }
  }
`;

const Title = styled('p')`
  font-family: var(--bold);
  font-style: normal;
  font-weight: bold;
  font-size: 41px;
  line-height: 150%;
  text-align: center;
  color: #18214D;
  @media only screen and (max-width: 580px) {
    font-size: 34px;
    text-align: left;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
    margin: auto;
  }
  @media only screen and (max-width: 500px) {
    font-size: 24px;
    width: 100%;
    margin-bottom: 8px !important;
  }
  @media only screen and (max-width: 350px) {
    font-size: 24px;
    width: 100%;
  }
`;
const PriceTitle = styled('p')`
  font-family: var(--bold);
  font-style: normal;
  font-weight: bold;
  font-size: 41px;
  line-height: 150%;
  text-align: center;
  color: #18214D;
  @media only screen and (max-width: 580px) {
    font-size: 34px;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
    margin: auto;
  }
  @media only screen and (max-width: 500px) {
    font-size: 24px;
    width: 100%;
    margin-bottom: 8px !important;
  }
  @media only screen and (max-width: 350px) {
    font-size: 24px;
    width: 100%;
  }
`;

const Text = styled('p')`
  width: 100%;
  display: flex;
  @media only screen and (max-width: 580px) {
    text-align: left;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
  @media only screen and (max-width: 500px) {
    font-size: 16px;
  }
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 180%;
  text-align: center;
  color: #18214D;
  margin-top: 12px;
`;

const Image = styled('div')`
  width: 460px;
  height: 300px;
  margin-right: 40px;
  transition: all .2s;
  margin-top: 40px;

    @media only screen and (max-width: 705px) {
        display: flex !important;
        align-items: center;
        justify-content: center;
        height: max-content !important;
        margin-right: 0px !important;
    }

  & img {
    width: -webkit-fill-available;
    margin-top: 0;
    border-radius: 10px;
    transition: all .2s;

      @media only screen and (max-width: 705px) {
          width: 80% !important;
      }
  }

  &:hover img {
    //box-shadow: 7px 7px #ffd02f;
  }

`

const Buttons = styled('div')`
  width: 100%;
  display: flex;

  @media only screen and (max-width: 548px) {
    flex-direction: column;
    & > :first-child {
      margin-right: 0 !important;
    }

    & > :last-child {
      margin-top: 20px !important;
    }

    & > * {
      width: 100% !important;
    }
  }
  align-items: center;
  justify-content: center;

  & > * {
    margin-top: 24px !important;
  }

  & > :first-child {
    margin-right: 20px;
  }
`;

const DiscountBox = styled('div')`
    width: 166px;
    position: absolute;
    right: 20%;
    bottom: 12%;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #2684FF;
`

const ArrowIcon = styled('div')`
    transform: rotate(0.5turn);
`

const DemoBtn = styled('div')`
    width: max-content;
    display: block;

    @media only screen and (max-width: 992px) {
        & {
            display: none;
        }
    }
`

const mappedItems = (items, open, setOpen) => {
    return _.map(items, (item, key) => {
        return (<Image>
            <img
                width={'100%'}
                src={item}
                alt=""
                onClick={() => setOpen({open: true, img: item})}
            />
        </Image>);
    });
}

const images = [[profit, img7, stat, finance, dashboard, border, kom], [kom, border, dashboard, finance, stat, img7, profit]]

const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const header = (t, open, setOpen) => (<Wrapper>
        <div style={{width: '100%'}}>

            <MainContainer style={{margin: 'auto'}} mP={'opx'}>

                <Navbar bg={bg}/>

                <Header>
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xs={12} lg={12} md={12} sm={12}>
                                    <SlideUp duration={.3}>
                                        <Title>
                                            {t('homeTitle')}
                                        </Title>
                                    </SlideUp>
                                </Col>
                                <Col xs={12} lg={7} md={10} sm={12}>
                                    <SlideUp delay={.2} duration={.4}>

                                        <Text>
                                            {t('homeParagraph')}
                                        </Text>
                                    </SlideUp>
                                </Col>
                                <SlideUp delay={.3} duration={.6}>

                                    <Buttons>
                                        <DemoBtn>
                                            <a href="#contact">
                                                <Button
                                                    background={'#2684FF'}
                                                    width={'238px'}
                                                    height={'53px'}>
                                                    {t('requestDemo')}
                                                </Button>
                                            </a>
                                        </DemoBtn>
                                        <a href="#contact">
                                            <Button
                                                width={'238px'}
                                                height={'53px'}>
                                                {t('getConsultation')}
                                            </Button>
                                        </a>
                                    </Buttons>
                                </SlideUp>
                            </Row>
                        </Col>
                    </Row>
                </Header>
            </MainContainer>
            <Row style={{marginTop: '30px'}}>
                <Col xs={12}>
                    <div className={'desktop'}>
                    <Marquee scrolldelay={100} behavior="scroll" direction="left" scrollamount="10">
                            <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                                {mappedItems(images[0])}
                            </div>
                        </Marquee>
                        <Marquee scrolldelay={100} behavior="scroll" direction="right" scrollamount="10">
                            <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                                {mappedItems(images[1])}
                            </div>
                        </Marquee>
                    </div>
                    <div className={'mobile'}>
                        <Slider {...settings2}>
                            {mappedItems(images[0], open, setOpen)}
                        </Slider>
                        {open.open && (
                            <Lightbox
                                medium={open.img}
                                large={open.img}
                                alt="Ritm"
                                onClose={() => setOpen({open: false})}
                            />
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    </Wrapper>

)

const GridColumn = styled(Row)`
  padding: 0 20px;
`;

const FlexWrap = styled('div')`
    width: 100%;
    display: flex;
    @media only screen and (max-width: 768px) {
        display: block !important;
    }
`

const WrapContent = styled('div')`
    width: 50%;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`

const priceBoxes = (tab, setTab, t) => {
    return (<div>
        <TabsBox>
            <Tabs>
                <Tab active={tab} onClick={() => setTab(true)}>{t('monthly')}</Tab>
                <Tab active={!tab} onClick={() => setTab(false)}>{t('annually')}</Tab>
            </Tabs>
            <DiscountBox>
                <div>{t('20%_discount_text')}</div>
                <ArrowIcon>
                    <img alt={''} src={Arrow}/>
                </ArrowIcon>
            </DiscountBox>
        </TabsBox>
        <BoxesWrapper>
            {_.map(priceItems, (priceItem, index) => {
                    const price = _.get(priceItem, 'price')
                    const discountPrice = _.get(priceItem, 'discountPrice')
                    const currency = _.get(priceItem, 'currency')
                    const period = _.get(priceItem, 'period')
                    const positions = _.get(priceItem, 'positions')
                return (
                    <SlideUp delay={Number(`.${index + (index * 3)}`)}>

                        <PriceBox key={index}>
                            <div className={'top'}>
                                <div className={'title'}>
                                    {tab ? t(price) : t(discountPrice)} {t(currency)}
                                </div>
                                <div className={'subTitle'}>
                                    /{t(period)}
                                </div>
                            </div>
                            <div className={'middle'}>
                                {_.map(positions, (position) => {
                                    return (<div className={'position'}>
                                            <Check/>
                                            {t(position.name)}
                                        </div>)
                                })}
                            </div>
                        </PriceBox>
                    </SlideUp>
                )
                })}
            </BoxesWrapper>
        </div>)
}

const sections = (t, tab, setTab, windowWidth) => (<Sections>
        <Services object={productsSection}/>
        <MainContainer mP={'0px'} id={'solutions'}>
            <FlexWrap>
                <WrapContent>
                    <SlideUp animationType={'left'}>
                        <Features
                            img={img7}
                            text={`${t('support')} <span>${t('for_supervisor')}</span>`}
                            options={[
                                {text: 'the_supervisor_text_1'},
                                {text: 'the_supervisor_text_2'},
                                {text: 'the_supervisor_text_3'},
                                {text: 'the_supervisor_text_4'},
                                {text: 'the_supervisor_text_5'}
                            ]}
                            computerImg={true}
                        />
                    </SlideUp>
                    <SlideUp animationType={'left'}>
                        <Features
                            img={mob3}
                            text={`${t('optimization')} <span>${t('delivery')}</span>`}
                            options={[
                                {text: 'delivery_text_1'},
                                {text: 'delivery_text_2'},
                                {text: 'delivery_text_3'},
                                {text: 'delivery_text_4'},
                                {text: 'delivery_text_5'},
                            ]}
                        />
                    </SlideUp>
                    <SlideUp animationType={'left'}>
                        <Features
                            img={stock}
                            text={`${t('management')} <span>${t('warehouse')}</span>`}
                            options={[
                                {text: 'warehouse_text_1'},
                                {text: 'warehouse_text_2'},
                                {text: 'warehouse_text_3'},
                                {text: 'warehouse_text_4'},
                                {text: 'warehouse_text_5'},
                            ]}
                            computerImg={true}
                        />
                    </SlideUp>
                    <SlideUp animationType={'left'}>
                        <Features
                            img={profit}
                            text={`${t('bosssupport')} <span>${t('for_founder')}</span>`}
                            options={[
                                {text: 'the_founder_text_1'},
                                {text: 'the_founder_text_2'},
                                {text: 'the_founder_text_3'},
                                {text: 'the_founder_text_4'},
                                {text: 'the_founder_text_5'},
                            ]}
                            computerImg={true}
                        />
                    </SlideUp>
                    <SlideUp animationType={'left'}>
                        <Features
                            img={debt}
                            text={`${t('collectionbegin')} <span>${t('collection')}</span>`}
                            options={[
                                {text: 'collection_text_1'},
                                {text: 'collection_text_2'},
                                {text: 'collection_text_3'},
                                {text: 'collection_text_4'},
                                {text: 'collection_text_5'},
                            ]}
                        />
                    </SlideUp>
                </WrapContent>
                <WrapContent>
                    <SlideUp animationType={'right'}>
                        <Features
                            img={mob1}
                            text={`${t('improvement')} <span>${t('agents_work')}</span>`}
                            options={[
                                {text: 'the_agents_text_1'},
                                {text: 'the_agents_text_2'},
                                {text: 'the_agents_text_3'},
                                {text: 'the_agents_text_4'},
                                {text: 'the_agents_text_5'},
                            ]}
                        />
                    </SlideUp>
                    <SlideUp animationType={'right'}>
                        <Features
                            img={stat}
                            text={`${t('auditsupport')} <span>${t('for_auditor')}</span>`}
                            options={[
                                {text: 'the_auditor_text_1'},
                                {text: 'the_auditor_text_2'},
                                {text: 'the_auditor_text_3'},
                                {text: 'the_auditor_text_4'},
                                {text: 'the_auditor_text_5'},
                            ]}
                            computerImg={true}
                        />
                    </SlideUp>
                    <SlideUp animationType={'right'}>
                        <Features
                            img={finance}
                            text={`${t('financial')} <span>${t('accounting')}</span>`}
                            options={[
                                {text: 'the_financier_text_1'},
                                {text: 'the_financier_text_2'},
                                {text: 'the_financier_text_3'},
                                {text: 'the_financier_text_4'},
                                {text: 'the_financier_text_5'},
                            ]}
                            computerImg={true}
                        />
                    </SlideUp>
                    <SlideUp animationType={'right'}>
                        <Features
                            img={dashboard}
                            text={`${t('facilities')} <span>${t('for_operator')}</span>`}
                            options={[
                                {text: 'the_operator_text_1'},
                                {text: 'the_operator_text_2'},
                                {text: 'the_operator_text_3'},
                                {text: 'the_operator_text_4'},
                                {text: 'the_operator_text_5'},
                            ]}
                            computerImg={true}
                        />
                    </SlideUp>
                    <SlideUp animationType={'right'}>
                        <Features
                            img={border}
                            text={`${t('help')} <span>${t('for_commercial_director')}</span>`}
                            options={[
                                {text: 'for_commercial_director_text_1'},
                                {text: 'for_commercial_director_text_2'},
                                {text: 'for_commercial_director_text_3'},
                                {text: 'for_commercial_director_text_4'},
                                {text: 'for_commercial_director_text_5'},
                            ]}
                            computerImg={true}
                        />
                    </SlideUp>
                </WrapContent>
            </FlexWrap>
        </MainContainer>
        <FlexibleReport/>
    <Section object={firstProduct}/>
    <MainContainer>
            <Projects/>
        </MainContainer>
        <Collaction/>

        <Clients t={t}/>
        <div
            id={'pricing'}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#F6FAFF',
                width: '100%',
                marginBottom: '50px'
            }}>
            <MainContainer style={{padding: '30px 10px 50px 10px'}}>
                <Row>
                    <Col xs={12}>
                        <SlideUp>
                            <PriceTitle style={{marginBottom: '20px'}}>
                                {t('prices')}
                            </PriceTitle>
                        </SlideUp>
                    </Col>
                    <Col xs={12}>
                        {priceBoxes(tab, setTab, t)}
                    </Col>
                </Row>
            </MainContainer>
        </div>
        <Comments t={t}/>
    </Sections>)

const Home = () => {


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState({open: false, img: null})

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array means this effect will only run once after the initial render


    const [tab, setTab] = useState(true)


    const {t} = useTranslation()


    return (<>
            {header(t, open, setOpen)}
            {sections(t, tab, setTab, windowWidth)}
            <Contact t={t}/>
            <Footer t={t}/>
        </>);
};

export default Home;
