import React from 'react';
import styled from 'styled-components';
import bga from 'media/Image/bg_2.svg';
import {Col, Row} from 'react-bootstrap';
import Button from 'components/Button';
import MainContainer from 'components/MainContainer';
import arrow from 'media/Image/arrow.svg';
import * as API from 'constants/api';
import axios from 'tools/axios';
import {Alert, Slide, Snackbar} from '@mui/material';
import Loader from 'components/Loader';
import $ from 'jquery';
import PropTypes from 'prop-types';
import SlideUp from "../../SlideUp/SlideUp";

const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 73px 0;

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  background: url(${({ bg }) => bg || bga}) center center / cover;

  & .row {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 995px) {
    .row {
      flex-direction: column !important;
    }
  }
  @media only screen and (max-width: 992px) {
    padding: 23px 0;
  }
`;

const Title = styled.h1`
  font-family: var(--bold);
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 150%;
  text-align: center;
  color: #18214D;
  @media only screen and (max-width: 580px) {
    font-size: 24px;
    text-align: left;
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Input = styled('input')`
  background: #FFFFFF;
  border: 1.02857px solid #CAD1E1;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  padding: 16px 24px;
  width: 100%;
  height: 51px;
`;

const Label = styled.label`
  width: 100%;

  & p {
    margin-bottom: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #5C6077;
  }
`;

const Img = styled('div')`
  position: absolute;
  right: 0;
  top: -40px;
  @media only screen and (max-width: 995px) {
    display: none;
  }
`;

const LoaderWrapper = styled('div')`
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 0.66);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000000;
`;

const Container = styled('div')`
  .MuiSnackbar-root {
    z-index: 5000;
  }
`

class Contact extends React.Component {
  constructor () {
    super();
    this.state = {
      'name': '',
      'phone': '',
      'open': false,
      'error': false,
      'loading': false
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  async handleSubmit (e) {
    e.preventDefault();
    const { name, phone } = this.state;
    this.setState({ 'loading': true });
    $('body').css('overflow', 'hidden');
    $('html').css('overflow', 'hidden');
    await axios().post(API.SEND_REQUEST_API, { 'full_name': name, 'phone_number': phone })
      .then(() => {
        this.setState({ 'open': true, 'error': false, 'loading': false });
        $('body').css('overflow', 'hidden scroll');
        $('html').css('overflow', 'hidden scroll');
      })
      .catch(() => {
        this.setState({ 'open': true, 'error': true, 'loading': false });
        $('html').css('overflow', 'hidden scroll');
        $('body').css('overflow', 'hidden scroll');
      });
  }

  handleClose = () => {
    this.setState({ 'open': false });
  };

  componentDidMount () {

  }

  render () {
    function TransitionLeft (props) {
      return <Slide {...props} direction="left"/>;
    }
    const snackbarStyles = {
      'error': {
        'width': '200px',
        'zIndex': '1000000000 !important',
        'background': '#d32f2f',
        'color': '#fff',
        '& svg': {
          'fill': '#fff'
        }
      },
      'success': {
        'zIndex': '1000000000 !important',
        'width': '250px',
        'background': '#2e7d32',
        'color': '#fff',
        '& svg': {
          'fill': '#fff'
        }
      }
    };
    const snackbar = (
            <>

                {
                    this.state.open && !this.state.error && <Snackbar
                        TransitionComponent={TransitionLeft}
                        open={true}
                        anchorOrigin={{ 'horizontal': 'right', 'vertical': 'top' }}
                        autoHideDuration={6000}
                        onClose={(e) => this.handleClose(e)}
                    >
                        {

                            <Alert
                                sx={snackbarStyles.success}
                                severity="success"
                            >Спасибо за вашу заявку</Alert>
                        }
                    </Snackbar>
                }{
                this.state.open && this.state.error && <Snackbar
                    TransitionComponent={TransitionLeft}
                    open={true}
                    onClose={(e) => this.handleClose(e)}
                    anchorOrigin={{ 'horizontal': 'right', 'vertical': 'top' }}
                    autoHideDuration={6000}
                >
                    <Alert
                        sx={snackbarStyles.error}
                        severity="error"
                    >Заполните поле</Alert>

                </Snackbar>
            }
            </>
    );
    const { bg } = this.props;
    const t = this.props.t
    const { loading } = this.state;

      return (
            <Container>
                {snackbar}
				{loading && <LoaderWrapper>
					<Loader/>
				</LoaderWrapper>}
                <Wrapper id={'contact'} bg={bg}>
                    <MainContainer>
                        <Row>
                            <Col lg={6} md={10} sm={12} xs={12} style={{ 'position': 'relative' }}>
                                <Col md={12} lg={10} xs={12}>
                                    <SlideUp animationType={'top'}>
                                        <Title>
                                            {t('contactTitle')}
                                        </Title>
                                    </SlideUp>
                                </Col>
                                <Img>
                                    <SlideUp animationType={'top'}>
                                    <img src={arrow} alt=""/>
                                    </SlideUp>
                                </Img>
                            </Col>
                            <Col lg={6} md={12} sm={12} xs={12}>
                                <SlideUp animationType={'bottom'}>
                                    <Form onSubmit={e => this.handleSubmit(e)}>
                                        <Col lg={8} xs={12}>
                                            <Label>
                                                <p>{t('nameHolder')}</p>
                                                <Input name={'name'} onChange={e => this.handleChange(e)}
                                                       placeholder={t('nameHolder')}/>
                                            </Label>
                                            <Label style={{'marginTop': '16px', 'marginBottom': '24px'}}>
                                                <p>{t('phoneHolder')}</p>
                                                <Input type={'number'} name={'phone'}
                                                       onChange={e => this.handleChange(e)}
                                                       placeholder={t('phoneHolder')}/>
                                            </Label>
                                            <Button
                                                width={'100%'}
                                                background={'#2684FF'}
                                                type={'submit'}
                                            >{t('contactButtonText')}</Button>
                                        </Col>
                                    </Form>
                                </SlideUp>
                            </Col>
                        </Row>
                    </MainContainer>
                </Wrapper>
            </Container>
    );
  }
}

Contact.propTypes = {
  'bg': PropTypes.string
};

export default Contact;
