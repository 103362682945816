import React from 'react';

export default function Location ({color, ...props}) {
  return (
	 <svg {...props} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
		 <path d="M24.283 22.7562C22.8883 22.2736 21.0711 21.9086 19.0543 21.6994C19.3638 21.2295 19.6808 20.7363 19.9986 20.2254C22.8787 15.5958 24.3391 11.933 24.3391 9.33873C24.339 4.18934 20.1497 0 15.0003 0C9.85101 0 5.66167 4.18934 5.66167 9.33873C5.66167 11.933 7.12206 15.5958 10.0022 20.2254C10.3199 20.7363 10.6369 21.2295 10.9465 21.6994C8.92974 21.9086 7.11239 22.2735 5.71774 22.7562C3.54292 23.5088 2.44019 24.5156 2.44019 25.7484C2.44019 27.13 3.81521 28.2221 6.52698 28.9946C8.80288 29.643 11.8121 30 15.0003 30C18.1885 30 21.1978 29.6429 23.4738 28.9946C26.1855 28.2221 27.5605 27.1299 27.5605 25.7483C27.5605 24.5156 26.4579 23.5088 24.283 22.7562ZM7.54569 9.33873C7.54569 5.22814 10.8898 1.88402 15.0003 1.88402C19.1109 1.88402 22.455 5.22814 22.455 9.33873C22.455 11.5414 21.0557 14.9565 18.4085 19.2146C17.1056 21.3104 15.79 23.1374 15.0003 24.1956C14.2106 23.1374 12.895 21.3104 11.5921 19.2146C8.94497 14.9565 7.54569 11.5414 7.54569 9.33873ZM22.9576 27.1826C20.8447 27.7845 18.0188 28.116 15.0003 28.116C11.9819 28.116 9.15591 27.7845 7.04314 27.1826C4.79683 26.5427 4.32415 25.8569 4.32415 25.7484C4.32415 25.646 4.68175 25.1084 6.33386 24.5367C7.82314 24.0213 9.87603 23.6512 12.1588 23.4832C13.3334 25.1593 14.2064 26.2634 14.2623 26.3338C14.441 26.5591 14.7128 26.6904 15.0003 26.6904C15.2879 26.6904 15.5596 26.5591 15.7384 26.3338C15.7943 26.2634 16.6673 25.1593 17.8418 23.4832C20.1247 23.6512 22.1776 24.0213 23.6669 24.5367C25.3189 25.1084 25.6765 25.646 25.6765 25.7483C25.6765 25.8569 25.2039 26.5427 22.9576 27.1826Z" fill={color || "#2684FF"}/>
		 <path d="M15.0004 4.97412C12.5937 4.97412 10.6357 6.93215 10.6357 9.33877C10.6357 11.7454 12.5938 13.7034 15.0004 13.7034C17.407 13.7034 19.3651 11.7454 19.3651 9.33877C19.3651 6.93215 17.4071 4.97412 15.0004 4.97412ZM15.0004 11.8195C13.6326 11.8195 12.5198 10.7066 12.5198 9.33883C12.5198 7.97096 13.6326 6.8582 15.0004 6.8582C16.3682 6.8582 17.481 7.97102 17.481 9.33883C17.481 10.7066 16.3682 11.8195 15.0004 11.8195Z" fill={color|| "#2684FF"}/>
	 </svg>
  );
}
