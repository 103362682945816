import navbar from './navbar'
import home from './home'
import footer from './footer'
import agentPage from "./agentPage";
import delivery from "./delivery";

const locales = {
  ru: {
    translation: {
      ...navbar.ru,
      ...home.ru,
      ...footer.ru,
      ...agentPage.ru,
      ...delivery.ru
    }
  },

  uz: {
    translation: {
      ...navbar.uz,
      ...home.uz,
      ...footer.uz,
      ...agentPage.uz,
      ...delivery.uz
    }
  },

  en: {
    translation: {
      ...navbar.en,
      ...home.en,
      ...footer.en,
      ...agentPage.en,
      ...delivery.en
    }
  }
}

export default locales
