import React from 'react';
import styled from 'styled-components';
import {Col, Row} from 'react-bootstrap';
import Logo from 'media/Icon/Logo';
import Facebook from 'media/Facebook';
import Instagram from 'media/Instagram';
import Telegram from 'media/Telegram';
import moment from 'moment';
import MainContainer from 'components/MainContainer';
import Phone from "../../media/Icon/Phone";
import Email from "../../media/Icon/Email/Email";
import Location from "../../media/Icon/Location";
import {useTranslation} from "react-i18next";
import Button from "../Button";

const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  justify-content: center;
  padding: 56px 0;
  background: #18214D;
  @media only screen and (max-width: 992px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }
  }
  @media only screen and (min-width: 992px) {
    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }
  }
  @media only screen and (max-width: 768px) {
    &  .row {
      width: 100%;
      margin: 0 10px;
    }
    & .mt-768-footer {
      margin-top: 50px !important;
    }
  }
  @media only screen and (max-width: 735px) {
    &  .row {
      margin: 0 !important;
    }
  }
  @media only screen and (max-width: 576px) {
    &  .mt-576-20 {
      margin-top: 30px !important;
    }
  }

  .mobile > div, .desktop > div {
    margin: auto;
  }
`

const LogoItem = styled('div')`
    display: flex;
    align-items: center;
    gap: 5px;
    
    & div {
        font-weight: 600;
        font-size: 55px;
        line-height: 0;
    }

  & svg {
    width: 45px;
    height: 45px;
  }
`

const MenuItem = styled('div')`
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 580px) {
    &.button {
      background-color: #2684F1 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 11px !important;
      color: #fff !important;
      text-transform: inherit !important;
      font-family: SemiBold !important;
      font-size: 16px !important;
      width: 100% !important;
      min-width: 100% !important;
      height: 49px !important;
    }
  }
  & p, & a {
    margin-bottom: -3px;
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #FFFFFF;
    margin-left: 18px;
  }
`


const Block = styled('div')`
  width: auto;
`

const Access = styled('div')`

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 56px;

  & p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: flex-end;
    color: #FFFFFF;
    opacity: 0.8;
  }
`
const DownloadLink = styled.a`
    color: #fff!important;
    margin-left: 5px !important;
    font-weight: 600 !important;
    text-decoration: underline !important;
    font-size: 22px !important;
`
const DownloadLinkWrapper = styled.div`
    margin-top: 47px;
`

const Icon = styled('div')`
  width: 42px;
  height: 42px;
  background: #2684FF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  transition: 0.5s all ease;
  justify-content: center;

  & svg, svg path {
    transition: 0.5s all ease;
  }

  &:hover {
    background: #fff;
    transition: 0.5s all ease;

    & svg, svg path {
      fill: #2684FF;
      transition: 0.5s;
    }
  }
`

const Relative = styled.div`
  margin-top: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top: 1px solid #344E70;

`

const Icons = styled('div')`
  width: auto;
  display: flex;
  align-items: center;
  &.mobile {
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    display: none;

  }
  @media only screen and (max-width: 992px) {
    

    &.mobile {
      display: flex;
    }
  }

  
  

  & > :not(:last-child) {
    margin-right: 32px;
  }
`

export default function Footer() {
    const {t} = useTranslation()

    return (
        <Wrapper>
            <MainContainer>
                <Row>
                    <Col md={5} lg={5} xs={12}>
                        <Row style={{alignItems: 'center'}}>
                            <Col xs={12}>
                                <LogoItem>
                                    <Logo isFooter={true}/>
                                    <div>Ритм</div>
                                </LogoItem>
                            </Col>
                            <Col xs={12}>
                                <MenuItem>
                                    <p style={{margin: '24px 0 0 5px'}}>OOO "Wiener Deming"</p>
                                </MenuItem>
                            </Col>
                            <Col xs={12}>
                                <MenuItem>
                                    <DownloadLinkWrapper>
                                        <DownloadLink
                                            href={`${process.env.PUBLIC_URL}/doc.docx`}
                                            download
                                        >
                                            {t('public_offer')}
                                        </DownloadLink>
                                    </DownloadLinkWrapper>
                                </MenuItem>
                            </Col>
                        </Row>
                    </Col>
                    <Col className={'mt-768-footer'} md={7} lg={7}  xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <Row style={{alignItems: 'center'}}>
                            <Col xs={12}>
                                <MenuItem style={{marginBottom: '24px', alignItems: 'flex-start'}}>
                                    <div>
                                        <Location color={'#FFF'}/>
                                    </div>
                                    <div>
                                        <p>{t('address')}</p>
                                        <p style={{marginTop: '10px'}}>{t('reference_point')}</p>
                                    </div>
                                </MenuItem>
                            </Col>
                            <Col xs={12} md={6} lg={6} sm={6}  >
                                <MenuItem className={'button'}>
                                    <div>
                                        <Phone/>
                                    </div>
                                    <a href={"tel:+998781138858"}>+998 78 113 88 58</a>
                                </MenuItem>
                            </Col>
                            <Col xs={12}  md={6} lg={6} sm={6} className={'mt-576-20'}  style={{display: 'flex', alignItems: 'flex-start'}}>
                                <MenuItem >
                                    <div style={{marginRight: '10px'}}>
                                        <Email/>
                                    </div>
                                    <a href="mailto:info@ritm.uz">
                                        info@ritm.uz
                                    </a>
                                </MenuItem>
                            </Col>
                            <Col xs={12}>
                                <Icons className={'mobile auto'}>
                                    <a rel={'noreferrer'} href={'https://t.me/RITM_videos'} target={'_blank'}>
                                        <Icon>
                                            <Telegram/>
                                        </Icon>
                                    </a>
                                    <a rel={'noreferrer'} href={'https://www.instagram.com/ritm_agent/'} target={'_blank'}>
                                        <Icon>
                                            <Instagram/>
                                        </Icon>
                                    </a>
                                    <a rel={'noreferrer'} href={'https://www.facebook.com/ritmagent/'} target={'_blank'}>
                                        <Icon>
                                            <Facebook/>
                                        </Icon>
                                    </a>
                                </Icons>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </MainContainer>
            <Relative className={'mobile auto'}>
                <MainContainer>
                    <Access>
                        <Col xs={12}>
                            <p>
                                © 2020 - {moment().format('YYYY')} Copyright. {t('copyright_text')}
                            </p>
                        </Col>
                    </Access>
                </MainContainer>
            </Relative>
            <Relative className={'desktop auto'}>
                <MainContainer>
                    <Access>
                        <Block>
                            <p>
                                © 2020 - {moment().format('YYYY')} Copyright. {t('copyright_text')}
                            </p>
                        </Block>
                        <Icons>
                            <a rel={'noreferrer'} href={'https://t.me/RITM_videos'} target={'_blank'}>
                                <Icon>
                                    <Telegram/>
                                </Icon>
                            </a>
                            <a rel={'noreferrer'} href={'https://www.instagram.com/uz.ritm?igsh=ZG8zd3RlbHhsejE5'} target={'_blank'}>
                                <Icon>
                                    <Instagram/>
                                </Icon>
                            </a>
                            <a rel={'noreferrer'} href={'https://www.facebook.com/ritmagent/'} target={'_blank'}>
                                <Icon>
                                    <Facebook/>
                                </Icon>
                            </a>
                        </Icons>
                    </Access>
                </MainContainer>
            </Relative>
        </Wrapper>
    )
}
