import React from 'react';

export default function PricingTwo (props) {
    return (
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 16C0 7.16344 7.16344 0 16 0H56C64.8366 0 72 7.16344 72 16V56C72 64.8366 64.8366 72 56 72H16C7.16344 72 0 64.8366 0 56V16Z" fill="white"/>
            <path d="M17 17H35.4865V55H17V17Z" fill="#2684FF"/>
            <path d="M35.4865 17H55V55H35.4865V17Z" fill="#8ABDFF"/>
            <path d="M35.4865 35.4863H55V55H35.4865V35.4863Z" fill="#D1E5FF"/>
        </svg>
    );
}
