import React from 'react'
import {Col, Row} from "react-bootstrap";
import _ from "lodash";
import {servicesSection} from 'constants/services';
import styled from "styled-components";
import Icon from 'components/Icon';
import MainContainer from 'components/MainContainer';
import SlideUp from "components/SlideUp/SlideUp";
import {useTranslation} from "react-i18next";

const Wrapper = styled(Row)`
  padding-top: 12px;
  padding-bottom: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  padding-bottom: 80px;

  .slick-dots li {
    & button:before {
      width: 8px;
      content: '';
      background-color: #5C6077;
      border-radius: 50%;
      height: 8px;
    }

    &.slick-active button:before {
      background-color: #2684FF;
    }
  }

  @media only screen and (max-width: 620px) {
    .slick-list {
      width: 150% !important;
    }
  }
  @media only screen and (max-width: 992px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media only screen and (min-width: 992px) {
    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }
  }

  .swiper-slide {
    margin-right: 35px !important;
  }
`

const Item = styled(Col)`
  margin-top: 48px !important;
  padding-left: 24px !important;
`

const Title = styled('p')`
  margin-top: 54px;
  font-family: var(--bold);
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 51px;
  text-align: center;
  color: #18214D;

  @media only screen and (max-width: 580px) {
    font-size: 34px;
    text-align: left;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
    margin: auto;
  }
  @media only screen and (max-width: 500px) {
    font-size: 28px;
    width: 100%;
    margin-bottom: 8px !important;
    line-height: 35px;
  }
  @media only screen and (max-width: 350px) {
    width: 100%;
  }
`

const ItemTitle = styled('p')`
  font-style: normal;
  font-family: var(--bold);
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #18214D;
  margin-top: 24px;
`

const ItemText = styled('p')`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 180%;
  color: #5C6077;
  max-width: 374px;
  margin-top: -2px;
`

const MobileItem = styled('div')`
  margin-top: 30px !important;
  padding-left: 24px !important;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  @media only screen and (max-width: 420px) {
    padding: 0 !important;
    width: 320px !important;
  }
`

const settings_2 = {
  dots:false,
  infinite:false,
  speed:500,
  slidesToShow:3,
  slidesToScroll:1,
  arrows:false,
  autoplay:true,
  autoplaySpeed:2000,
  initialSlide:0,
  responsive:[
    {
      breakpoint:1150,
      settings:{
        slidesToShow:2,
        slidesToScroll:1,
        infinite:true,
        dots:true
      }
    },
    {
      breakpoint:440,
      settings:{
        slidesToShow:1,
        slidesToScroll:1,
        infinite:true,
        dots:true
      }
    }
  ]
}

const Services = ({object, title}) => {
  const items = object || servicesSection
  const {t} = useTranslation()
  return (
      <MainContainer id={'possibilities'}>
        <Wrapper>
          <Col lg={8} md={12} sm={12} xs={12}>
            <SlideUp animationType={'top'} duration={.3}>
              <Title>
                {t(title) || t('all_tasks_are_solved_by_one_program')}
              </Title>
            </SlideUp>
          </Col>
          <Col className={'desktop'} xs={12}>
            <Row>
              {
                _.map(items, (item, key) => {
                  const icon = _.get(item, ['icon'])
                  const title = _.get(item, ['title'])
                  const text = _.get(item, ['text'])
                  const delay = _.get(item, ['delay'])
                  return (
                      <Item key={key} lg={3} xs={12} md={6} sm={12}>
                        <SlideUp delay={_.toNumber(delay)} animationType={'bottom'}>
                          <Icon>
                            {icon}
                          </Icon>
                          <ItemTitle>
                            {t(title)}
                          </ItemTitle>
                          <ItemText>
                            {t(text)}
                          </ItemText>
                        </SlideUp>
                      </Item>
                  )
                })
              }
            </Row>
          </Col>
          <Col xs={12}>
            {/*<Slider className={'mobile'} {...settings_2}>*/}
            {/*  {*/}
            {/*    _.map(items, (item, key) => {*/}
            {/*      const icon = _.get(item, ['icon'])*/}
            {/*      const title = _.get(item, ['title'])*/}
            {/*      const text = _.get(item, ['text'])*/}
            {/*      return (*/}
            {/*          <MobileItem key={key}>*/}
            {/*            <Icon>*/}
            {/*              {icon}*/}
            {/*            </Icon>*/}
            {/*            <ItemTitle>*/}
            {/*              {t(title)}*/}
            {/*            </ItemTitle>*/}
            {/*            <ItemText>*/}
            {/*              {t(text)}*/}
            {/*            </ItemText>*/}
            {/*          </MobileItem>*/}
            {/*      )*/}
            {/*    })*/}
            {/*  }*/}
            {/*</Slider>*/}
            <div className={'mobile'} style={{width: '100%'}}>
              <Row style={{width: '100%'}}>
                {
                  _.map(items, (item, key) => {
                    const icon = _.get(item, ['icon'])
                    const title = _.get(item, ['title'])
                    const text = _.get(item, ['text'])
                    return (
                        <Col key={key} lg={12} xs={12} md={12} sm={6}>
                          <MobileItem>
                            <Icon>
                              {icon}
                            </Icon>
                            <ItemTitle>
                              {t(title)}
                            </ItemTitle>
                          </MobileItem>
                        </Col>
                    )
                  })
                }
              </Row>
            </div>
          </Col>
        </Wrapper>
      </MainContainer>
  )
}

export default Services
