import React from 'react';

export default function PricingThree(props) {
    return (
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 16C0 7.16344 7.16344 0 16 0H56C64.8366 0 72 7.16344 72 16V56C72 64.8366 64.8366 72 56 72H16C7.16344 72 0 64.8366 0 56V16Z"
                fill="#2684FF"/>
            <path
                d="M57.1639 46.6464L57.1732 46.6413H57.1551L35.8849 35.1152L14.6143 46.6413H14.5962L14.6054 46.6464L14.5962 46.6515H14.6143L35.8849 58.1776L57.1551 46.6515H57.1732L57.1639 46.6464Z"
                fill="#D1E5FF"/>
            <path
                d="M57.1639 24.5312L57.1732 24.5265H57.1551L35.8849 13L14.6143 24.5265H14.5962L14.6054 24.5312L14.5962 24.5362H14.6143L35.8849 36.0627L57.1551 24.5362H57.1732L57.1639 24.5312Z"
                fill="#2684FF"/>
            <path d="M14.6143 24.0625V47.1151L35.8849 35.589L14.6143 24.0625Z" fill="#8ABDFF"/>
            <path d="M57.155 24.0625V47.1151L35.8849 35.589L57.155 24.0625Z" fill="#8ABDFF"/>
        </svg>
    );
}
