import React from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CustomText = styled('div')`
  font-style: normal;
  font-weight: normal;

  font-size: ${({isMobile}) => isMobile ? '13px' : '17px'};
  line-height: 170%;

	color: #18214D;
	font-family: var(--semi-bold);
`;

const Wrapper = styled(Box)`
	max-height: ${({isMobile}) => isMobile ? '260px' : '350px'};
	height: ${({isMobile}) => isMobile ? '260px' : '350px'};
	overflow-x: hidden;
	overflow-y: scroll;
	&::-webkit-scrollbar {
    width: 0;
	}
`;

const CommentBody = ({ text,isMobile }) => {
  return (
	 <Box padding={'0'}>
		 <Wrapper isMobile={isMobile}>
			 <CustomText isMobile={isMobile} >
				 {text}
			 </CustomText>
		 </Wrapper>
	 </Box>
  );
};

CommentBody.propTypes = {
  'text': PropTypes.string,
  'isMobile': PropTypes.bool
};

export default CommentBody;
