import React from 'react'
import styled from "styled-components";
import _ from "lodash";
import Check from "../../media/Icon/Check";
import {useTranslation} from "react-i18next";

const Wrapper = styled('div')`
  background: #F6F6F9;
  padding: 50px;
  border: 1px solid #E4E9F1;

  @media only screen and (max-width: 450px) {
    padding: 20px ;
  }
  @media only screen and (max-width: 390px) {
    padding: 15px ;
  }

  @media only screen and (max-width: 768px) {
    border-radius: 0;
  }
  border-radius: 16px;
`

const Title = styled('h3')`
  font-weight: 600;
  margin-bottom: 12px;
  font-size: 30px;
  line-height: 1.25;

  & span {
    color: #6B43EF
  }

`

const SubTitle = styled('p')`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.75;
  color: #6A7995;
`

const Img = styled('div')`
  width: 100%;
  margin: 0 -36px -36px 0;

    @media only screen and (max-width: 450px) {
        margin: 0px !important;
    }
    
    & > img {
      width: -webkit-fill-available;
        box-shadow: ${({computerImg}) => computerImg ? 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;' : 'unset'};
        border-radius: ${({computerImg}) => computerImg ? '8px' : 'unset'};
    }  
`

const MainWrapper = styled('div')`
  margin: 8.5px;
  @media only screen and (max-width: 768px) {
    margin: 10px 0 !important;
  }
`

const OptionsList = styled('ul')`
  padding: 0;
  
  color: ${({primary}) => primary ? 'white' : 'inherit'};
  margin: 0;
  margin-top: 15px !important;
  margin-bottom: 15px !important;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
`

const OptionsItem = styled('li')`
  width: 100%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 450px) {
    padding: 7px 0;
    & > div:first-child {
      margin-right: 7px;
    }
  }
  & svg {
    
      width: 20px;
      height: 20px;
  }
`

const OptionIcon = styled('div')`
    width: 8%;
`

const OptionText = styled('p')`
  width: calc(100% - 2%);
  margin-bottom: 0;
  font-family: var(--semi-bold);
  font-size: 16px;
  color: ${({primary}) => primary ? '#D9DBE9' : 'inherit'};
`

const Features = ({
        text,
        subtitle,
        img,
        options = [{text: 'Option 1'}, {text: 'Option 1'}],
        computerImg = false
    }) => {

    const {t} = useTranslation()

    return (
        <MainWrapper>
            <Wrapper>
                <Title dangerouslySetInnerHTML={{__html: text}} />
                <SubTitle>{subtitle}
                </SubTitle>
                <OptionsList>
                    {
                        _.map(options, (item) => {
                            const text = _.get(item, ['text'])
                            return (
                                <OptionsItem>
                                    <OptionIcon>
                                        <Check color={'#6B43EF'} />
                                    </OptionIcon>
                                    <OptionText >
                                        {t(text)}
                                    </OptionText>

                                </OptionsItem>
                            )
                        })
                    }
                </OptionsList>
               <Img computerImg={computerImg}>
                   <img src={img} alt=""/>
               </Img>
            </Wrapper>
        </MainWrapper>
    )
}

export default Features
