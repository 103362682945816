import React from 'react';

export default function Code (props) {
  return (
	 <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		 <g clipPath="url(#clip0_824_1790)">
			 <path d="M8.43758 22.5C8.19758 22.5 7.95758 22.4087 7.77508 22.225C7.40883 21.8587 7.40883 21.265 7.77508 20.8987L11.1738 17.5L7.77383 14.1C7.40758 13.7337 7.40758 13.14 7.77383 12.7737C8.14008 12.4075 8.73383 12.4075 9.10008 12.7737L13.1626 16.8362C13.5288 17.2025 13.5288 17.7962 13.1626 18.1625L9.10008 22.225C8.91758 22.4087 8.67758 22.5 8.43758 22.5Z" fill="#2684FF"/>
			 <path d="M21.5625 22.5H15.9375C15.42 22.5 15 22.08 15 21.5625C15 21.045 15.42 20.625 15.9375 20.625H21.5625C22.08 20.625 22.5 21.045 22.5 21.5625C22.5 22.08 22.08 22.5 21.5625 22.5Z" fill="#2684FF"/>
			 <path d="M26.5625 28.75H3.4375C1.54125 28.75 0 27.2088 0 25.3125V4.6875C0 2.79125 1.54125 1.25 3.4375 1.25H26.5625C28.4587 1.25 30 2.79125 30 4.6875V25.3125C30 27.2088 28.4587 28.75 26.5625 28.75ZM3.4375 3.125C2.57625 3.125 1.875 3.82625 1.875 4.6875V25.3125C1.875 26.1738 2.57625 26.875 3.4375 26.875H26.5625C27.4237 26.875 28.125 26.1738 28.125 25.3125V4.6875C28.125 3.82625 27.4237 3.125 26.5625 3.125H3.4375Z" fill="#2684FF"/>
			 <path d="M29.0625 7.5H0.9375C0.42 7.5 0 7.08 0 6.5625C0 6.045 0.42 5.625 0.9375 5.625H29.0625C29.58 5.625 30 6.045 30 6.5625C30 7.08 29.58 7.5 29.0625 7.5Z" fill="#2684FF"/>
		 </g>
		 <defs>
			 <clipPath id="clip0_824_1790">
				 <rect width="30" height="30" fill="white"/>
			 </clipPath>
		 </defs>
	 </svg>
  );
}
