import React from 'react';

export default function Sell (props) {
  return (
	 <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
		 <path d="M21.051 10.6665C21.0015 10.2214 20.6253 9.88466 20.1774 9.88466H18.2321V8.88077C18.2321 7.35798 16.9932 6.11902 15.4704 6.11902C13.9476 6.11902 12.7087 7.35798 12.7087 8.88077V9.88466H10.7634C10.3156 9.88466 9.93937 10.2214 9.88992 10.6665L8.94849 19.1392C8.92089 19.3876 9.00041 19.6361 9.1671 19.8224C9.3338 20.0087 9.57199 20.1151 9.82201 20.1151H21.1189C21.3689 20.1151 21.6071 20.0087 21.7738 19.8224C21.9405 19.6361 22.02 19.3876 21.9924 19.1392L21.051 10.6665ZM14.4665 8.88077C14.4665 8.32724 14.9168 7.87683 15.4704 7.87683C16.0239 7.87683 16.4743 8.32724 16.4743 8.88077V9.88466H14.4664V8.88077H14.4665ZM10.804 18.3573L11.5501 11.6425H12.7087V12.6464C12.7087 13.1317 13.1023 13.5253 13.5876 13.5253C14.073 13.5253 14.4666 13.1317 14.4666 12.6464V11.6425H16.4744V12.6464C16.4744 13.1317 16.8679 13.5253 17.3533 13.5253C17.8387 13.5253 18.2322 13.1317 18.2322 12.6464V11.6425H19.3908L20.1369 18.3573H10.804Z" fill="#2684FF"/>
		 <path d="M15.4709 0C7.71896 0 1.41234 6.30668 1.41234 14.0586C1.41234 16.9433 2.27208 19.6936 3.90404 22.0474L0.563665 28.728C0.275969 29.3034 0.687883 30.0002 1.35005 30C1.4874 30 1.62579 29.9678 1.75359 29.9017L8.63384 26.3431C10.7173 27.5053 13.0702 28.1171 15.4709 28.1171C23.2229 28.1171 29.5295 21.8105 29.5295 14.0586C29.5295 6.30668 23.2228 0 15.4709 0ZM15.4709 26.3593C13.2244 26.3593 11.0262 25.7472 9.11372 24.589C8.85193 24.4306 8.52656 24.4196 8.25468 24.5602L3.34259 27.1008L5.71423 22.3576C5.86323 22.0596 5.83089 21.7029 5.63073 21.4365C4.02099 19.2945 3.17015 16.7432 3.17015 14.0586C3.17015 7.27594 8.68822 1.75781 15.4709 1.75781C22.2536 1.75781 27.7717 7.27594 27.7717 14.0586C27.7717 20.8412 22.2535 26.3593 15.4709 26.3593Z" fill="#2684FF"/>
	 </svg>
  );
}
