export const Rules = [
    {
        title: 'Политика конфиденциальности программы «Ритм»',
        texts: [
            'Уважаемый пользователь! Обращаем ваше внимание, что принятие вами политики конфиденциальности означает, что вы (пользователь):',
            '- Cоглашаетесь с условиями сбора персональных данных, конфиденциальных данных и иной информации, предоставляете полное и безусловное согласие на обработку информации со стороны OOO "Wiener Deming" ТМ «Ритм» (далее – Компания);',
            '- Cоглашаетесь и обязуетесь соблюдать условия настоящей Политики конфиденциальности, подтверждаете Вашу дееспособность, а также в полной мере отдаете отчет своим действиям и руководите ими, действуя добровольно и в своем интересе.',
        ]
    },
    {
        title: '1. Общие положения',
        texts: [
            '1.1 Политика конфиденциальности (далее — «Политика»), применяемая при использовании Приложения распространяется на отношения Компании и юридического лица и/или индивидуального предпринимателя, являющегося Пользователем Приложения (далее – «Пользователь»), действует в отношении информации, которую Компания может получить о Пользователе и от Пользователя при регистрации и во время использования им данного Приложения.',
            '1.2 Загрузка и использование Приложения означает безоговорочное согласие Пользователя с положениями политики конфиденциальности и указанными в ней условиями сбора и обработки информации.',
            '1.3 В целях настоящей Политики под персональной информацией Пользователя понимаются:',
            '1.3.1 Персональная информация, которую Пользователь предоставляет о себе самостоятельно в процессе использования функциональных сервисов Приложения.',
            'При получении согласия Пользователя, Приложение собирает данные от Пользователя, получает доступ к контактам телефонной книги Пользователя и доступ к галерее фотографий и камере Пользователя.',
            '1.3.2 Данные, которые автоматически передаются сервисам Приложения в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения. В том числе IP-адрес (или иной программе, с помощью которой осуществляется доступ к сервисам), технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к сервисам, адреса запрашиваемых страниц и любая другая информация, необходимая для корректной работы Приложения и обеспечения выполнения всех запросов Пользователя.',
            'Во время использования Приложения Компания также может собирать определенную информацию о пользовании Приложением, которая не относится к персональным данным и, не является конфиденциальной. Полученные сведения используются для анализа и улучшения работы Приложения, в целях разработки, совершенствования и предоставления Пользователям отвечающих их запросам функциональных возможностей Приложения, повышения качества и быстродействия его работы.',
            '1.3.3 Иная информация о Пользователе, обработка которой предусмотрена Пользовательским соглашением.',
            '1.4 Под обработкой персональных данных понимаются действия (операции) с персональными данными, включая сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, обезличивание, блокирование и уничтожение персональных данных.',
            'Персональные данные обрабатываются автоматизированным способом, с использованием передачи по сети Интернет.',
        ]
    },
    {
        title: '2. КОНФИДЕНЦИАЛЬНОСТЬ В МОБИЛЬНОМ ПРИЛОЖЕНИИ НА ANDROID И APPLE IOS',
        texts: [
            '2.1 Компания собирает информацию о Пользователях Приложения автоматически при использовании Приложения.',
            '2.1.1 При загрузке, регистрации или использовании данного Приложения, Пользователь предоставляет определенную запрашиваемую информацию, которая может включать в себя имя, номер мобильного телефона, адрес электронной почты или другую контактную информацию.',
            '2.1.2 В данном разделе описываются типы хранящихся на мобильном устройстве данных, к которым приложения могут получать доступ, а именно:',
            'Доступ в интернет (INTERNET) используется для синхронизации данных Приложения и данных на серверах разработчика.',
            'Доступ к камере устройства (CAMERA) необходим для вызова встроенной камеры мобильного устройства. Это позволяет делать снимки, сохранять фотографии объектов и сканировать QR-кода бежинга, а также для распознавания лиц при отметке, приходе или уходе сотрудника.',
            'Доступ к микрофону (RECORD_AUDIO) необходим для использования функции голосового поиска в документах.',
            'Разрешение ACCESS_FINE_LOCATION, ACCESS_COARSE_LOCATION. Приложение собирает сведения о местоположении, если эти данные необходимы для работы приложения и программно-аппаратных комплексов. Определение местоположения Пользователя используется во время отметки сотрудника и для отслеживания его передвижений, которое работает в фоновом режиме.',
            'Разрешение ACCESS_BACKGROUND_LOCATION необходимо для работы функции контроля работы выездного сотрудника. GPS координаты записываются в базу данных, формируя список перемещений, затем загружаются на сервер, откуда руководитель запрашивает отчет и может сравнить запланированный маршрут с фактическим. Сотрудники предупреждены о фиксации и отправке GPS данных на сервер, в центре уведомлений устройства есть индикатор работы этой функции.',
            'Запись данных в память устройства: сохранение данных в памяти устройства в объемах, необходимых для реализации функционала приложений.',
            'Получение состояния телефона (READ_PHONE_STATE): получение данных о состоянии телефона (состояние звонков, IMEI, номер телефона). Используется для идентификации пользователей, приглушения звуков уведомлений приложений во время звонков.',
            'Разрешение USE_FINGERPRINT, USE_BIOMETRIC используется для входа в аккаунт, если пользователь настроил вход в акаунт с локальным паролем и включил вход с Touch-ID.',
            '2.1.3 Компания обязуется принимать все необходимые меры для защиты персональных данных Пользователя от неправомерного доступа к ним или их раскрытия.',
        ]
    },
    {
        title: '3. Способы сбора информации Компании',
        texts: [
            '3.1 При загрузке, доступе и использовании Приложения для сбора определенного вида (типа) информации используется технология автоматического сбора информации и отслеживания, в частности для сбора следующих сведений:',
            '3.1.1 Детали использования, включая данные о входе и использовании других информационных ресурсов, с которыми Пользователь связан, к которым Пользователь имеет доступ и использует в Приложении.',
            '3.1.2 Информация об устройстве, включая доступ к функционалу мобильного телефона или компьютера, информацию о мобильном устройстве или компьютере и подключении к Интернету, в том числе уникальный идентификатор устройства, IP-адрес, операционная система, тип браузера, информация о сети мобильной связи и номер телефона устройства и т. д.',
            '3.1.3 Приложение также может получить доступ и к другой информации, связанной с другими файлами, хранящимися на устройстве Пользователя, массивами информации, включая мультимедийные материалы, включающие в себя, например, фотографии, аудио и видео клипы.',
        ]
    },
    {
        title: '4. Цели обработки персональной информации пользователей',
        texts: [
            '4.1 Приложение собирает и хранит персональную информацию, которая необходима для обеспечения корректного и оперативного функционирования Приложения и/или исполнения соглашений с Пользователем.',
            '4.2 Персональную информацию (данные) Пользователя Приложение обрабатывает в следующих целях:',
            '4.2.1 Идентификации Пользователя, в т. ч. при регистрации в Приложении и создании учетной записи Пользователя, для обеспечения надлежащего функционирования Приложения.',
            '4.2.2 Предоставления Пользователю доступа к персонализированным ресурсам Приложения.',
            '4.2.3 Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Приложения, изменения функциональных возможностей Приложения, появлении доступных обновлений Приложения, изменениях в любых продуктах или услугах, которые Компания предлагает или предоставляем.',
            '4.2.4 Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.',
            '4.2.5 Предоставления Пользователю клиентской и технической поддержки при возникновении проблем, связанных с использованием Приложения.',
        ]
    },
    {
        title: '5. Условия обработки персональной информации пользователей и ее передачи третьим лицам',
        texts: [
            '5.1 В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц.',
            '5.2 Компания вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:',
            '5.2.1 Пользователь выразил согласие на такие действия любым способом, в том числе используя Приложение в режиме полной функциональности.',
            '5.2.2 Передача необходима для использования Пользователем определенного сервиса либо для исполнения определенного соглашения с Пользователем.',
            '5.2.3 Передача предусмотрена законодательством Республики Узбекистан или иным применимым законодательством.',
            '5.3 Обработка персональных данных Пользователя осуществляется без ограничения срока любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.',
            'Обработка персональных данных Пользователей осуществляется в соответствии с:',
            '- Закон Республики Узбекистан «О персональных данных» от 02.07.2019 за №ЗРУ-547;',
            '- Закон Республики Узбекистан «Об информатизации» от 11.12.2003 за №560-II.',
            '- договора, заключаемые между Компанией и Пользователем;',
            '- согласие Пользователя на обработку их персональных данных;',
            'и иные нормативно-правовые акты.',
        ]
    },
    {
        title: '6. Доступ и исправление персональной информации',
        texts: [
            '6.1 Пользователь может просматривать и изменять личную информацию, войдя в Приложение иили на странице личного профиля.',
            '6.2 Компания в праве удалить личную информацию и учетную запись Пользователя, если Пользователем был не соблюден любой из пунктов пользовательского соглашения приложения или в случае использования Приложения с нарушением норм законодательства Республики Узбекистан.',
        ]
    },
    {
        title: '7. Безопасность данных',
        texts: [
            '7.1 Компания принимает необходимые и достаточные организационные и технические меры, направленные на обеспечение безопасности личной информации пользователей от случайной потери, несанкционированного доступа, использования, изменения и раскрытия информации.',
            '7.2 Информация, которая предоставляется пользователями Приложения, хранится на защищенных серверах за межсетевыми экранами.',
            '7.3 Безопасность и защита информации Пользователя также зависит от Пользователя.',
        ]
    },
    {
        title: '8. Изменения в политике конфиденциальности',
        texts: [
            '8.1 Компания оставляет за собой право изменять настоящую Политику конфиденциальности в любое время и без предварительного предупреждения и согласия Пользователя.',
            '8.2 Дата последнего изменения Политики конфиденциальности, указывается в верхней части страницы. Пользователь несет ответственность за периодическое посещение этой страницы Политики конфиденциальности для проверки на наличие каких-либо изменений и/или ознакомления с ними.',
            '8.3 Дальнейшее использование Пользователем Приложения, после внесения изменений в Политику конфиденциальности, считается безусловным принятием Пользователем этих изменений.',
        ]
    }
]
