import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled('div')`
	max-width: 1170px;
  width: 100%;
	@media only screen and (max-width: 1170px) {
      padding: ${({mP}) => mP || '0 20px'};
  }
  ${({style}) => style}}
  ${({styles}) => styles}}
`;
const MediaContainer = styled('div')`
	max-width: 1170px;
  width: 100%;

  ${({style}) => style}}
  ${({styles}) => styles}}
`;

function MainContainer (props) {
  const { children, withouMedia } = props;
  if (withouMedia) {
      return (
          <MediaContainer {...props}>
              {children}
          </MediaContainer>
      )
  }
  return (
	 <Container {...props}>
		 {children}
	 </Container>
  );
}
MainContainer.propTypes = {
  'children': PropTypes.any
};

export default MainContainer;
