import React from 'react';

export default function Instagram (props) {
  return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1143_4001)">
                <path
                    d="M21.4158 6.69823C21.404 5.81648 21.239 4.94351 20.928 4.11835C20.6583 3.42233 20.2464 2.79022 19.7186 2.26241C19.1908 1.7346 18.5587 1.32269 17.8626 1.053C17.0481 0.747227 16.1875 0.581892 15.3177 0.564031C14.1977 0.513971 13.8426 0.5 10.9996 0.5C8.15666 0.5 7.79226 0.5 6.68045 0.564031C5.81097 0.582023 4.95084 0.747356 4.13666 1.053C3.44053 1.3225 2.80833 1.73434 2.28049 2.26218C1.75265 2.79002 1.34081 3.42222 1.07131 4.11835C0.764925 4.93228 0.599948 5.7926 0.583506 6.66214C0.533445 7.78327 0.518311 8.13835 0.518311 10.9813C0.518311 13.8243 0.518311 14.1876 0.583506 15.3005C0.600969 16.1714 0.765122 17.0305 1.07131 17.8467C1.34126 18.5426 1.75341 19.1745 2.28142 19.7021C2.80944 20.2297 3.4417 20.6414 4.13782 20.9108C4.94978 21.2289 5.81005 21.4061 6.68161 21.4347C7.80274 21.4848 8.15782 21.4999 11.0008 21.4999C13.8438 21.4999 14.2082 21.4999 15.32 21.4347C16.1898 21.4176 17.0504 21.2527 17.865 20.9469C18.5608 20.6769 19.1927 20.2649 19.7205 19.7371C20.2483 19.2094 20.6603 18.5774 20.9303 17.8816C21.2365 17.0666 21.4007 16.2075 21.4181 15.3355C21.4682 14.2155 21.4833 13.8604 21.4833 11.0163C21.481 8.17327 21.481 7.81237 21.4158 6.69823ZM10.9927 16.3576C8.01928 16.3576 5.61054 13.9489 5.61054 10.9755C5.61054 8.00214 8.01928 5.5934 10.9927 5.5934C12.4201 5.5934 13.7891 6.16044 14.7984 7.16978C15.8077 8.17913 16.3748 9.54809 16.3748 10.9755C16.3748 12.4029 15.8077 13.7719 14.7984 14.7812C13.7891 15.7906 12.4201 16.3576 10.9927 16.3576ZM16.589 6.64933C15.894 6.64933 15.334 6.08818 15.334 5.39432C15.334 5.22958 15.3664 5.06646 15.4295 4.91427C15.4925 4.76207 15.5849 4.62379 15.7014 4.5073C15.8179 4.39082 15.9562 4.29842 16.1084 4.23537C16.2606 4.17233 16.4237 4.13989 16.5884 4.13989C16.7531 4.13989 16.9163 4.17233 17.0685 4.23537C17.2207 4.29842 17.3589 4.39082 17.4754 4.5073C17.5919 4.62379 17.6843 4.76207 17.7474 4.91427C17.8104 5.06646 17.8428 5.22958 17.8428 5.39432C17.8428 6.08818 17.2817 6.64933 16.589 6.64933Z"
                    fill="white"/>
                <path
                    d="M10.9927 14.4717C12.9235 14.4717 14.4888 12.9064 14.4888 10.9756C14.4888 9.04475 12.9235 7.47949 10.9927 7.47949C9.06184 7.47949 7.49658 9.04475 7.49658 10.9756C7.49658 12.9064 9.06184 14.4717 10.9927 14.4717Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_1143_4001">
                    <rect width="21" height="21" fill="white" transform="translate(0.5 0.5)"/>
                </clipPath>
            </defs>
        </svg>
  );
}
