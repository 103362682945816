export default {
  ru: {
    deliveryMainTitle: 'Ритм.Доставка поможет настроить службу приема онлайн-заказов',
    deliveryMainText: 'Ритм.Доставка позволяет быстро и просто настроить собственную службу приема онлайн-заказов и контроля доставки для предпринимателей.',
    step1: 'Моментально уведомляем о проблемах',
    step2: 'Повышаем эффективность каждого сотрудника',
    step3: 'Маркетенговые инструменты для увеличения продаж',
    step4: 'Ускорим оборот денег',
    step5: 'Интеграция с оборудованием',

    product1Title: 'Как работает система',
    product1Text1: 'KPI на продажу || Дайте бонус от фактических продаж и установите градацию вознаграждения',
    product1Text2: 'KPI на ассортимент и ММЛ || Установите другие планы продаж и бонусы на высокомаржинальные продукты и увеличьте прибыль',
    product1Text3: 'KPI на АКБ || Увеличьте покрытие территории. Дополнительно, вы можете установить бонусы на АКБ для определенного ассортимента.',
    product1Text4: 'KPI за оплату || Ускорьте закрытие долгов и увеличьте поступление денег',
    product1Text5: 'KPI за доставку|| Дайте бонус за доставленные заказы по объему, цене или за килограмм',
    product1Text6: 'KPI на дисиплину || Мотивируйте агента на своевременный первый визит в территорию. Дайте бонус, если вовремя посещено, и контролируйте через электронный табел',

    product2Title: 'Каким компаниям подходит Ритм.Доставка',
    product2MainText: 'Ритм.Доставка подходит всем компаниям, желающим осуществлять доставку своей продукции потребителю. Ритм.Доставка позволяет наладить систему онлайн-заказов и контроля доставки для следующих видов предприятий и товаров',
    product2Text1: 'Рестораны, кафе, производство хлебобулочных изделий',
    product2Text2: 'Фармацевтические, гигиенические и косметические продукты и товары',
    product2Text3: 'Доставка воды',
    product2Text4: 'Бытовая техника и канцтовары',
    product2Text5: 'Мебельные компании',
    product2Text6: 'Торговля специфическими товарами и продуктами',
  },
  uz: {
    deliveryMainTitle: 'Ritm yetkazib berish sizga onlayn buyurtma berish xizmatini sozlashda yordam beradi',
    deliveryMainText: 'Rhythm yetkazib berish tadbirkorlar uchun onlayn buyurtma berish va yetkazib berishni nazorat qilish xizmatini tez va oson sozlash imkonini beradi.',
    step1: 'Muammolar haqida sizni darhol xabardor qilamiz',
    step2: 'Biz har bir hodimning ish samaradorligini oshiramiz',
    step3: 'Savdoni oshirish uchun marketing vositalari',
    step4: 'Keling, pul muomalasini tezlashtiraylik',
    step5: 'Uskunalarni birlashtirish',

    product1Title: 'Как работает система',
    product1Text1: 'Sotuv bo\'yicha KPI || Amaldagi sotuvlardan bonus bering va mukofotlar gradatsiyasini o\'rnating',
    product1Text2: 'Assortiment va MML bo\'yicha KPI || Yuqori marjali mahsulotlar uchun alohida planlar, bonuslarni o\'rnating va foydani oshiring',
    product1Text3: 'AKB bo\'yicha KPI || Hududiy qamrovni kengaytiring. Qo\'shimcha ravishda, ma\'lum assortiment uchun AKB bonuslarini o\'rnatishingiz mumkin.',
    product1Text4: 'To\'lov bo\'yicha KPI || Qarzdorlikni tezroq yopishni jadallashtiring va pul tushumlarini oshiring',
    product1Text5: 'Yetkazib berish bo\'yicha KPI || Yetkazib berilgan buyurtmalar uchun hajm, narx yoki kilogramm bo\'yicha bonus bering\n',
    product1Text6: 'Intizom bo\'yicha KPI || Agentni hududga o\'z vaqtida birinchi tashrif uchun rag\'batlantiring. Agar o\'z vaqtida tashrif buyurilgan bo\'lsa, bonus bering va elektron jadval orqali nazorat qiling',

    product2Title: 'Ritm yetkazib berish uchun qaysi kompaniyalar mos keladi',
    product2MainText: 'Ritm yetkazib berish o\'z mahsulotlarini iste\'molchiga etkazib berishni istagan barcha kompaniyalar uchun javob beradi. Ritm.Dostavka quyidagi turdagi korxonalar va tovarlar uchun onlayn buyurtmalar va yetkazib berishni nazorat qilish tizimini yo\'lga qo\'yish imkonini beradi.',
    product2Text1: 'Restoranlar, kafelar, non mahsulotlari ishlab chiqarishs',
    product2Text2: 'Farmatsevtika, gigiena va kosmetika mahsulotlari va tovarlari',
    product2Text3: 'Suv yetkazib berish',
    product2Text4: 'Maishiy texnika va ish yuritish buyumlari',
    product2Text5: 'Mebel kompaniyalari',
    product2Text6: 'Muayyan tovarlar va mahsulotlar bilan savdo qilish',
  },
  en: {
    deliveryMainTitle: 'Rhythm.Delivery will help you set up an online ordering service',
    deliveryMainText: 'Rhythm.Delivery allows you to quickly and easily set up your own online ordering and delivery control service for entrepreneurs.',
    step1: 'We notify you immediately of problems',
    step2: 'We increase the efficiency of each employee',
    step3: 'Marketing tools to increase sales',
    step4: 'Let\'s speed up the circulation of money',
    step5: 'Equipment integration',

    product1Title: 'How the system works',
    product1Text1: 'KPI for sales || Customers place and track orders through a bot',
    product1Text2: 'KPI for assortment and MML || Dispatcher, courier and client receive notifications about the status of the order',
    product1Text3: 'KPI for batteries (Accumulators) || It will reduce costs in logistics, increase the quality of service and help control drivers online',
    product1Text4: 'KPI for payment || Delivery control in the application',
    product1Text5: 'KPI for delivery || The system builds the optimal route and navigation for the delivery of all orders',
    product1Text6: 'KPI for discipline || convenient tool for all users - courier service operators, couriers and customers - in the form of a mobile or web application',

    product2Title: 'Which companies are suitable for Rhythm.Delivery',
    product2MainText: 'Rhythm.Delivery is suitable for all companies wishing to deliver their products to the consumer. Ritm.Dostavka allows you to set up a system of online orders and delivery control for the following types of enterprises and goods',
    product2Text1: 'Restaurants, cafes, production of bakery products',
    product2Text2: 'Pharmaceutical, hygiene and cosmetic products and goods',
    product2Text3: 'Water delivery',
    product2Text4: 'Household appliances and stationery',
    product2Text5: 'Furniture companies',
    product2Text6: 'Trade in specific goods and products',
  }
}
