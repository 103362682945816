import React from 'react';
import {Box} from '@material-ui/core';
import styled from 'styled-components';
import Text from 'components/Text';
import PropTypes from 'prop-types';

const Row = styled('div')`
	display: flex;
	align-items: flex-start;
`;

const Img = styled('div')`
	max-width: 48px;
	min-width: 48px;
	min-height: 48px;
	max-height: 48px;
	border-radius: 50%;
	overflow: hidden;
	background: #6c6c6c;

	& img {
		width: 100%;
		height: 100%;
	}
`;

const About = styled('div')`
	text-align: left;
	margin-left: 17px;
`;

const Title = styled(Text)`
	font-style: normal;
	font-family: var(--bold) !important;
	font-weight: 700;
	font-size: 20px !important;
	line-height: 19px;
	color: #18214D;
`;

const CustomText = styled('p')`
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	font-weight: 700 !important;
	font-family: var(--regular);
	line-height: 19px;
	color: #71717A;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
	font-size: 17px !important;
`;


const Header = ({
  img,
  name,
	isMobile,
  direction
}) => {
  return (
		<Box
			marginBottom={'6px'}
			// bgcolor={'#FFFAF0'}
		>
			<Row>
				<Img>
					<img src={img} alt="user"/>
				</Img>
				<About>
					<Title>
						{name}
					</Title>
					<CustomText isMobile={isMobile}>
						{direction}
					</CustomText>
				</About>
			</Row>
		</Box>
  );
};

Header.propTypes = {
  'img': PropTypes.string,
  'isMobile': PropTypes.bool,
  'name': PropTypes.string,
  'direction': PropTypes.string
};

export default Header;
