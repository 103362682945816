import React from 'react';

export default function Statistic (props) {
  return (
	 <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		 <path d="M27.1875 27.3125H2.8125C2.56386 27.3125 2.3254 27.2137 2.14959 27.0379C1.97377 26.8621 1.875 26.6236 1.875 26.375V3.625C1.875 3.37636 1.97377 3.1379 2.14959 2.96209C2.3254 2.78627 2.56386 2.6875 2.8125 2.6875C3.06114 2.6875 3.2996 2.78627 3.47541 2.96209C3.65123 3.1379 3.75 3.37636 3.75 3.625V25.4375H27.1875C27.4361 25.4375 27.6746 25.5363 27.8504 25.7121C28.0262 25.8879 28.125 26.1264 28.125 26.375C28.125 26.6236 28.0262 26.8621 27.8504 27.0379C27.6746 27.2137 27.4361 27.3125 27.1875 27.3125Z" fill="#2684FF"/>
		 <path d="M24.3749 11.375H18.7499C18.5645 11.375 18.3833 11.43 18.2292 11.5331C18.075 11.6361 17.9549 11.7825 17.884 11.9538C17.813 12.125 17.7945 12.3135 17.8306 12.4953C17.8668 12.6772 17.956 12.8442 18.0871 12.9753L20.2367 15.125L15.9374 19.4243L12.8502 16.3372C12.7632 16.2501 12.6599 16.181 12.5462 16.1339C12.4324 16.0867 12.3105 16.0625 12.1874 16.0625C12.0643 16.0625 11.9424 16.0867 11.8287 16.1339C11.7149 16.181 11.6116 16.2501 11.5246 16.3372L5.89961 21.9622C5.81007 22.0487 5.73865 22.1521 5.68952 22.2665C5.64038 22.3809 5.61452 22.5039 5.61344 22.6284C5.61236 22.7529 5.63608 22.8763 5.68322 22.9915C5.73035 23.1067 5.79997 23.2114 5.88799 23.2994C5.97601 23.3875 6.08069 23.4571 6.1959 23.5042C6.31112 23.5513 6.43457 23.5751 6.55905 23.574C6.68353 23.5729 6.80655 23.547 6.92093 23.4979C7.03531 23.4488 7.13875 23.3774 7.22524 23.2878L12.1874 18.3257L15.2746 21.4128C15.3616 21.4999 15.4649 21.569 15.5787 21.6161C15.6924 21.6633 15.8143 21.6875 15.9374 21.6875C16.0605 21.6875 16.1824 21.6633 16.2962 21.6161C16.4099 21.569 16.5132 21.4999 16.6002 21.4128L21.5624 16.4507L23.7121 18.6003C23.8432 18.7314 24.0103 18.8206 24.1921 18.8568C24.3739 18.893 24.5624 18.8744 24.7337 18.8034C24.9049 18.7325 25.0513 18.6124 25.1544 18.4582C25.2574 18.3041 25.3124 18.1229 25.3124 17.9375V12.3125C25.3124 12.0639 25.2137 11.8254 25.0378 11.6496C24.862 11.4738 24.6236 11.375 24.3749 11.375ZM23.4374 15.6743L21.0131 13.25H23.4374V15.6743Z" fill="#2684FF"/>
	 </svg>
  );
}
